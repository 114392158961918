<template>
  <div class="Smoking">
    <div class="Smoking_list" v-for="(item, i) in swiper_list" :key="i">
      <!-- @click="handleClickSlide(item)" -->
      <div :class="'smoke_info' + i" id="smoke_info" v-if="item">
        <div class="img">
          <img :src="pathUrl + item.w_photo" alt="" />
          <!-- {{ "smoke_info" + i }} -->
        </div>
        <div class="info">
          <div class="name">
            {{ item.w_name }}<span class="smoke">抽烟</span>
            <br />
            {{ $Format(item.s_time, "times") }}
          </div>
        </div>
        <!-- <div class="name">{{ item.w_name }}</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
import { GetSyncattendance, GetSyncsmoke } from "../../api/adminBoard";
import { urlConfig } from "@/config/config";
export default {
  name: "Smoking",
  props: {
    bc_id: {},
  },
  data() {
    return {
      swiper_list: [
        // { w_name: "name3", s_time: "1689350433" },
        // { w_name: "name3", s_time: "1689350433" },
        // { w_name: "name3", s_time: "1689350433" },
        // { w_name: "name3", s_time: "1689350433" },
        // { w_name: "name4", s_time: "1689350003" },
      ],
      swiper_list1: [],
      queryParam: { bc_id: "" },
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      setIntervalFun: null,
      setIntervalFunClear: null,
      test: [],
    };
  },
  created() {},

  mounted() {
    this.setIntervalFunClear = setInterval(() => {
      // this.swiper_list = [];
      // }, 1000 * 60 * 1);
    }, 1000 * 60 * 60);
    // let that = this;

    this.setIntervalFun = setInterval(() => {
      this.loadData();

      setTimeout(() => {
        if (this.swiper_list1) {
          for (let index = 0; index < this.swiper_list1.length; index++) {
            setTimeout(() => {
              this.swiper_list.push(this.swiper_list1[index]);
            }, 200 * (index + 1));
            setTimeout(() => {
              this.swiper_list = Array.from(new Set(this.swiper_list1));
              // console.log(this.swiper_list);
            }, 200 * (index + 2));
          }
        } else {
          // this.swiper_list.push(this.swiper_list1[0]);
        }
        // 去重;
        // setTimeout(() => {
        //   this.swiper_list = Array.from(new Set(this.swiper_list1));
        //   // console.log(this.swiper_list);
        // }, 200 * this.swiper_list1.length);
        // this.swiper_list = Array.from(new Set(this.swiper_list));
        // console.log(this.swiper_list);
      }, 20);
    }, 1000); //展示一秒之内前10条数据

    this.getClassName();
  },

  watch: {
    bc_id(NValue, OValue) {
      this.queryParam.bc_id = NValue;
      this.loadData();
      // console.log(this.queryParam.bc_id);
    },
  },
  methods: {
    async loadData() {
      this.swiper_list1 = [];
      const orderParam = ["bc_id"];
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      await GetSyncsmoke(params)
        .then((res) => {
          let data = res.data;
          data.forEach((element) => {
            this.swiper_list1.push(Object.values(element)[0]);
            // this.swiper_list = [Object.values(element)[0]];
            // this.swiper_list = [Object.values(element)[data.length - 1]];
          });

          // this.swiper_list1.push(
          //   { w_name: "name3", s_time: "1689350433" },
          //   { w_name: "name3", s_time: "1689350655" }
          //   // { w_name: "name3", s_time: "1689350433" },
          //   // { w_name: "name3", s_time: "1689350433" },
          //   // { w_name: "name3", s_time: "1689350433" }
          // );
        })
        .then(() => {
          // this.getClassName();
        });
    },
    getClassName() {
      for (let index = 0; index < 100; index++) {
        let name = document.getElementsByClassName("smoke_info" + index);
        name.style.zIndex = 10 + index;
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.setIntervalFun);
    clearInterval(this.setIntervalFunClear);
  },
};
</script>

<style lang="less" scoped>
.Smoking {
  z-index: 999;
  // height: 50%;
  // position: absolute;

  // left: 0;
  // height: 500px;
  .Smoking_list {
    // display: none;
    // background-color: #fff;
    // position: absolute;
    width: 100%;
    height: 100px;
    // position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    // position: relative;
    #smoke_info {
      border: #ffffff83 1px solid;
      // top: 50px;
      width: 100%;
      position: absolute;
      height: 100px;
      // background-color: pink;
      // background-color: #008080;
      background-color: #f59a23;
      border-radius: 10px;
      padding: 2px 10px;
      display: flex;
      // margin: 5px;
      animation: move 5s;
      @keyframes move {
        0% {
          bottom: -100px;
          // opacity: 1;
        }
        50% {
          bottom: 50px;
          opacity: 1;
        }
        100% {
          bottom: 150px;
          opacity: 0;
          // display: none;
        }
      }
      animation-fill-mode: forwards; //forwards：动画在结束时保持最后一帧的样式
      .img {
        // position: absolute;
        width: 40%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .info {
        // position: absolute;
        height: 100%;
        width: 60%;
        text-align: left;
        // color: #ffff;
        .name {
          padding: 10px;
          padding-bottom: 0;
          .smoke {
            font-weight: 800;
          }
          #w_post {
            background-color: #f59a23;
            border-radius: 10px;
            padding: 2px 10px;
            // display: block;
          }
        }
        .ws_name {
          padding: 10px;
          //  width: 200px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      // animation-fill-mode: backwards; //  backwards：动画在开始时使用第一帧的样式
    }
  }
}
// }
</style>
