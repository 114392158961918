<template>
  <div class="OnlinerateManager">
    <!-- 在线率低的项目经理 -->
    <div class="title">
      <div class="title_days">
        在线率低的项目经理
        <div class="title_days_info">
          <a-tooltip
            placement="right"
            :get-popup-container="getPopupContainer"
            overlayClassName="popover"
          >
            <template slot="title"
              >统计昨天前20名设备累计在线时长小于2小时的项目经理</template
            >
            <img
              src="../../assets/images/visualize/title_icon.png"
              style="padding: 0"
            />
          </a-tooltip>
        </div>
      </div>
      <div class="changeBtn">
        <!-- <span v-show="changeBtnShow">柱状图</span
        ><span v-show="!changeBtnShow">轮播图</span> -->
        <img
          v-show="!changeBtnShow"
          src="../../assets/images/adminBoard/01-1.png"
          alt=""
          @click="isChangeBtn(true)"
        />
        <img
          v-show="changeBtnShow"
          src="../../assets/images/adminBoard/01-2.png"
          alt=""
        />
        <img
          v-show="changeBtnShow"
          src="../../assets/images/adminBoard/02-1.png"
          alt=""
          @click="isChangeBtn(false)"
        />

        <img
          v-show="!changeBtnShow"
          src="../../assets/images/adminBoard/02-2.png"
          alt=""
        />
      </div>
    </div>
    <!--swiper-no-swiping 禁止拖动  -->
    <div class="swiper-container" :id="currentIndex" v-if="changeBtnShow > 0">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, i) in swiper_list"
          :key="i"
          :id="item.slider"
        >
          <div class="swiper-slide-img">
            <div v-if="item.w_photo == null" class="noPhoto">
              {{ item.w_name }} <br />暂无图片
            </div>
            <div v-else class="img">
              <img :src="pathUrl + item.w_photo" alt="" />
            </div>
          </div>
          <div class="swiper-slide-name">
            <a-tooltip placement="bottomLeft">
              <template slot="title">
                <span> {{ item.w_name }}</span>
              </template>
              {{ item.w_name }}
            </a-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="OnlinerateManagerData" v-else>
      <OnlinerateManagerData :bc_id="bc_id" />
    </div>
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
import { urlConfig } from "@/config/config";
import { GetLowonlinejl } from "../../api/adminBoard";
import OnlinerateManagerData from "./OnlinerateManagerData.vue";
export default {
  name: "OnlinerateManager",
  components: {
    OnlinerateManagerData,
  },
  props: {
    bc_id: {},
  },
  data() {
    return {
      currentIndex: 2,
      queryParam: { bc_id: "" },
      swiper_list: [],
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      changeBtnShow: true,
    };
  },
  created() {},
  mounted() {
    // this.initSwiper(); //不能放在created里
    this.loadData();
  },
  watch: {
    bc_id(NValue, OValue) {
      this.queryParam.bc_id = NValue;
      this.loadData();
      // console.log(this.queryParam.bc_id);
    },
  },
  methods: {
    getPopupContainer(trigger) {
      return trigger.parentElement;
    },
    initSwiper() {
      let vm = this;
      vm.mySwiper = new Swiper("#" + vm.currentIndex, {
        loop: true, // 循环模式选项 当播放到最后一张图片后，点击按钮是否会继续轮播
        slidesPerView: 4, //一次显示4个
        // slidesPerView: 5, //一次显示10个
        slidesPerGroup: 1, //一次往前移一个
        spaceBetween: 5,
        // slidesPerColumn: 2, //多行 —— 一屏显示2行
        // loopedSlides: 1,
        // loopFillGroupWithBlank: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: false, //修改swiper的父元素时，自动初始化swiper
        autoplay: true,
        autoplay: {
          //   delay: 1000 * 60, // 自动播放的间隔时间，单位ms，默认3000ms
          delay: 2000,
          disableOnInteraction: false, // 用户操作后是否停止自动播放，默认为true，即停止。改为false，表示不会停止自动播放
        },
        // 如果需要前进后退按钮
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        // 如果需要分页器 即轮播图下面的小圆点
        // pagination: {
        //   el: ".swiper-pagination",
        // },
        onSlideChangeEnd: function (swiper) {
          swiper.update();
          this.mySwiper.startAutoplay();
          this.mySwiper.reLoop();
        },
        on: {
          // slideChangeTransitionEnd: function () {
          //   // vm.currentIndex;
          //   // console.log(vm.currentIndex);
          // },
          click: function (sw) {
            let target = sw.target;
            // console.log(target);
            vm.swiper_list.forEach((el) => {
              // console.log(el.w_name);
              // console.log(target.src);
              if (target.innerText.indexOf(el.w_name) != -1) {
                // 跳转到设备列表页面
                localStorage.setItem("path", "DeviceList");
                vm.$router.push({
                  path: "/DeviceList",
                  query: {
                    w_name: el.w_name,
                    bc_id: vm.queryParam.bc_id,
                  },
                });
              }
              if (target.src && target.src.indexOf(el.w_photo) != -1) {
                // 跳转到设备列表页面
                localStorage.setItem("path", "DeviceList");
                vm.$router.push({
                  path: "/DeviceList",
                  query: {
                    w_name: el.w_name,
                    bc_id: vm.queryParam.bc_id,
                  },
                });
              }
              // else if (target.src.indexOf(el.w_photo) != -1) {
              //   console.log(222);
              // }
              // if (el.w_photo === null) {
              //   // el.w_photo = el.w_name;
              //   // console.log(target.innerText);
              //   // console.log(el.w_name);
              //   // console.log(target.innerText.indexOf(el.w_name) != -1);
              //   // console.log(el.w_photo);
              //   if (target.innerText.indexOf(el.w_name) != -1) {
              //     // console.log(111);
              //     // // 跳转到设备列表页面
              //     localStorage.setItem("path", "DeviceList");
              //     vm.$router.push({
              //       path: "/DeviceList",
              //       query: {
              //         w_name: el.w_name,
              //         bc_id: vm.queryParam.bc_id,
              //       },
              //     });
              //   } else {
              //     // console.log("111+");
              //   }
              // } else {
              //   if (
              //     target.innerText.indexOf(el.w_name) != -1 ||
              //     target.src.indexOf(el.w_photo) != -1
              //   ) {
              //     console.log(222);
              //     // console.log(el.si_eventworker);
              //     // console.log(el.w_photo);
              //     // 跳转到设备列表页面
              //     // localStorage.setItem("path", "DeviceList");
              //     // vm.$router.push({
              //     //   path: "/DeviceList",
              //     //   query: {
              //     //     w_name: el.w_name,
              //     //     d_status: "0",
              //     //     bc_id: vm.queryParam.bc_id,
              //     //   },
              //     // });
              //   } else {
              //     // console.log("222+");
              //   }
              // }
            });
          },
        },
      });
    },
    async loadData() {
      this.swiper_list = [];
      const orderParam = ["bc_id"];
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      await GetLowonlinejl(params)
        .then((res) => {
          // console.log("在线率低的项目经理", res);
          this.swiper_list = res.data;
          // console.log(this.swiper_list);
        })
        .then(() => {
          this.initSwiper(); //不能放在created里
        });
    },
    // 切换按钮
    isChangeBtn(text) {
      // this.changeBtnShow = !this.changeBtnShow;
      this.changeBtnShow = text;
      if (this.changeBtnShow) {
        this.loadData();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.OnlinerateManager {
  width: 100%;
  height: 100%;
  .title {
    width: 100%;
    height: 15%;
    color: #66ffff;
    text-align: left;
    padding-left: 10px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    .title_days {
      width: 60%;
      display: flex;
      img {
        width: 12px;
        height: 12px;
      }
    }
    .changeBtn {
      cursor: pointer;
      font-size: 12px;
      img {
        width: 15px;
        height: 15px;
        margin: 5px;
        margin-left: 0;
      }
    }
    .title_days_info {
      padding-left: 10px;
      cursor: pointer;
      width: 20px;
      /deep/.ant-tooltip-inner {
        width: 300px !important;
      }
    }
  }
  .swiper-container {
    width: 100%;
    height: 85%;
    .swiper-slide {
      // background-color: pink;
      cursor: pointer;
      .swiper-slide-img {
        width: 100%;
        height: 80%;

        // background-color: #fff;
        .noPhoto {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .img {
          height: 100%;
          width: 100%;
        }
        img {
          height: 100%;
          width: 100%;
        }
      }
      .swiper-slide-name {
        width: 100%;
        height: 20%;
        // background-color: #fff;
        color: #d5f3f3;
        // display: flex;
        justify-content: center;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .OnlinerateManagerData {
    width: 100%;
    height: 100%;
  }
}
</style>
