<template>
  <div class="AlarmStatistics">
    <div class="title">
      <div class="title_days">报警事件统计</div>
    </div>
    <div class="AlarmStatistics_data">
      <div class="top">
        <div class="left">
          <div class="withHarf">
            <div class="displayflex">
              <div class="displayflex displayflex_left">
                <div class="num" @click="GoAlarmEventCY()">
                  {{ resData.smoke_sum }}
                </div>
                次
              </div>
              <div class="displayflex displayflex_right">
                <div class="num" @click="GoAlarmEventCY_zuori()">
                  {{ resData.smoke_yesterday }}
                </div>
                次
                <div class="num_times">
                  <span v-if="resData.smoke_add >= 0">+</span
                  >{{ resData.smoke_add }}
                </div>
              </div>
            </div>

            <div class="transform_scale">抽烟报警(7日)/ 今日</div>
          </div>
        </div>
        <div class="right">
          <div class="withHarf">
            <div class="displayflex">
              <div class="displayflex displayflex_left">
                <div class="num" @click="GoAlarmEventMCGZ()">
                  {{ resData.clothes_sum }}
                </div>
                次
              </div>
              <div class="displayflex displayflex_right">
                <div class="num" @click="GoAlarmEventMCGZ_zuori()">
                  {{ resData.clothes_yesterday }}
                </div>
                次
                <div class="num_times">
                  <span v-if="resData.clothes_add >= 0">+</span
                  >{{ resData.clothes_add }}
                </div>
              </div>
            </div>

            <div class="transform_scale">未穿工服(7日)/今日</div>
          </div>
        </div>
      </div>
      <div class="buttom">
        <div class="left">
          <div class="withHarf">
            <div class="displayflex">
              <div class="displayflex displayflex_left">
                <!-- @click="()" -->
                <div class="num" @click="GoSiteNeatSXTWQ()">
                  {{ resData.camera_sum }}
                </div>
                次
              </div>
              <div class="displayflex displayflex_right">
                <!-- @click="()" -->
                <div class="num" @click="GoSiteNeatSXTWQ_zuori()">
                  {{ resData.camera_yesterday }}
                </div>
                次
                <div class="num_times">
                  <span v-if="resData.camera_add >= 0">+</span
                  >{{ resData.camera_add }}
                </div>
              </div>
            </div>
            <div class="transform_scale">摄像头歪曲(7日)/今日</div>
          </div>
        </div>
        <div class="right">
          <div class="withHarf">
            <div class="displayflex">
              <div class="displayflex displayflex_left">
                <div class="num" @click="GoSiteNeatCha()">
                  {{ resData.factory_sum }}
                </div>
                次
              </div>
              <div class="displayflex displayflex_right">
                <div class="num" @click="GoSiteNeatCha_zuori()">
                  {{ resData.factory_yesterday }}
                </div>
                次
                <div class="num_times">
                  <span v-if="resData.factory_add >= 0">+</span
                  >{{ resData.factory_add }}
                </div>
              </div>
            </div>

            <div class="transform_scale">工地整洁度差(7日)/今日</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getScreenEventcount } from "@/api/screen";
export default {
  name: "AlarmStatistics",
  props: {
    bc_id: {},
  },
  data() {
    return {
      yesterdayStartTime: "",
      yesterdayEndTime: "",
      queryParam: { bc_id: "" },
      resData: {
        camera_add: "0",
        camera_sum: "0",
        camera_yesterday: "0",
        clothes_add: "0",
        clothes_sum: "0",
        clothes_yesterday: "0",
        factory_add: "0",
        factory_sum: "0",
        factory_yesterday: "0",
        smoke_add: "0",
        smoke_sum: "0",
        smoke_yesterday: "0",
      },
      sevenDayStartTime: "",
      todayEndTime: "",
    };
  },
  created() {
    this.getStartEndTime();
    this.loadData();
    let time = new Date().getTime() - 1000 * 60 * 60 * 24 * 7;
    this.sevenDayStartTime = parseInt(time / 1000);
    this.todayEndTime = parseInt(new Date().getTime() / 1000);
  },
  watch: {
    bc_id(NValue, OValue) {
      this.queryParam.bc_id = NValue;
      this.loadData(NValue);
      // console.log(this.queryParam.bc_id);
    },
  },
  methods: {
    getStartEndTime(num = 1) {
      // 一天的毫秒数
      const MillisecondsADay = 24 * 60 * 60 * 1000 * num;
      // 今日开始时间戳
      const todayStartTime = new Date(
        new Date().setHours(0, 0, 0, 0)
      ).getTime();
      // 今日结束时间戳
      const todayEndTime = new Date(
        new Date().setHours(23, 59, 59, 999)
      ).getTime();

      // 昨日开始时间戳
      const yesterdayStartTime = todayStartTime - MillisecondsADay;
      // 昨日结束时间戳
      const yesterdayEndTime = todayEndTime - MillisecondsADay;
      this.yesterdayStartTime = yesterdayStartTime / 1000;
      this.yesterdayEndTime = yesterdayEndTime.toString().slice(0, 10) * 1;
      // console.log(this.yesterdayStartTime, this.yesterdayEndTime);
      return [yesterdayStartTime, yesterdayEndTime];
    },
    async loadData(bc_id) {
      const params = {
        bc_id: bc_id ? bc_id : "",
      };
      await getScreenEventcount(params).then((res) => {
        // console.log("报警事件", res.data);
        this.resData = res.data;
        // console.log(this.resData);
      });
    },
    // 按钮
    GoAlarmEventCY() {
      localStorage.setItem("path", "AlarmEvent");
      this.$router.push({
        name: "AlarmEvent",
        params: {
          e_events: "smoking",
          e_time_start: this.sevenDayStartTime,
          e_time_end: this.yesterdayEndTime,
          bc_id: this.bc_id,
          si_eventworker: "0",
        },
      });
    },
    GoAlarmEventCY_zuori() {
      localStorage.setItem("path", "AlarmEvent");
      this.$router.push({
        name: "AlarmEvent",
        params: {
          // e_time_start: this.yesterdayStartTime,
          // e_time_end: this.yesterdayEndTime,
          e_events: "smoking",
          bc_id: this.bc_id,
          si_eventworker: "0",
        },
      });
    },
    GoAlarmEventMCGZ() {
      localStorage.setItem("path", "AlarmEvent");
      this.$router.push({
        name: "AlarmEvent",
        params: {
          e_time_start: this.sevenDayStartTime,
          e_time_end: this.yesterdayEndTime,
          e_events: "noclothes",
          bc_id: this.bc_id,
          si_eventworker: "1",
        },
      });
    },
    GoAlarmEventMCGZ_zuori() {
      localStorage.setItem("path", "AlarmEvent");
      this.$router.push({
        name: "AlarmEvent",
        params: {
          // e_time_start: this.yesterdayStartTime,
          // e_time_end: this.yesterdayEndTime,
          e_events: "noclothes",
          bc_id: this.bc_id,
          si_eventworker: "1",
        },
      });
    },
    GoSiteNeatSXTWQ() {
      localStorage.setItem("path", "SiteNeat");
      this.$router.push({
        name: "SiteNeat",
        params: {
          e_time_start: this.sevenDayStartTime,
          e_time_end: this.yesterdayEndTime,
          e_events: "4",
          bc_id: this.bc_id,
        },
      });
    },
    GoSiteNeatSXTWQ_zuori() {
      localStorage.setItem("path", "SiteNeat");
      this.$router.push({
        name: "SiteNeat",
        params: {
          // e_time_start: this.yesterdayStartTime,
          // e_time_end: this.yesterdayEndTime,
          e_events: "4",
          bc_id: this.bc_id,
        },
      });
    },
    GoSiteNeatCha() {
      localStorage.setItem("path", "SiteNeat");
      this.$router.push({
        name: "SiteNeat",
        path: "/SiteNeat",
        params: {
          e_events: "3",
          e_time_start: this.sevenDayStartTime,
          e_time_end: this.yesterdayEndTime,
          bc_id: this.bc_id,
        },
      });
    },
    GoSiteNeatCha_zuori() {
      localStorage.setItem("path", "SiteNeat");
      this.$router.push({
        name: "SiteNeat",
        path: "/SiteNeat",
        params: {
          // e_time_start: this.yesterdayStartTime,
          // e_time_end: this.yesterdayEndTime,
          e_events: "3",
          bc_id: this.bc_id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.AlarmStatistics {
  width: 100%;
  height: 100%;
  .title {
    width: 100%;
    height: 15%;
    color: #66ffff;
    text-align: left;
    padding-left: 10px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    .title_days {
      width: 50%;
      display: flex;
      align-items: center;
    }
  }
  .AlarmStatistics_data {
    height: 85%;
    width: 100%;

    .top,
    .buttom {
      width: 100%;
      height: 50%;
      display: flex;
      color: #fff;
      font-size: 12px;
    }

    .left,
    .right {
      height: 100%;
      // width: 50%;
      display: flex;
      width: 100%;
      //   align-items: center;
      justify-content: center;
      border: 1px dashed #0c41707c;
      border-bottom: none;
      border-right: none;
      align-items: center;
      .withHarf {
        // width: 60%;
      }
      .yesterday {
        // width: 40%;
      }
      .num {
        font-size: 24px;
        color: #66ffff;
      }
      .num_times {
        color: red;
      }
      .displayflex {
        display: flex;
        align-items: flex-end;
        // align-items: center;
        justify-content: center;
        // transform: scale(0.82);
        width: 100%;
        .displayflex_left {
          width: 60%;
          cursor: pointer;
        }
        .displayflex_right {
          width: 40%;
          cursor: pointer;
        }
      }
      .transform_scale {
        transform: scale(0.9);
      }
    }
  }
}
</style>
