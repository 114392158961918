<template>
  <div class="CameraDistortion">
    <!-- 摄像头歪曲 -->
    <div class="title">
      <div class="title_days">
        摄像头歪曲<span class="title_days_info">
          <a-tooltip
            placement="right"
            :get-popup-container="getPopupContainer"
            overlayClassName="popover"
          >
            <template slot="title">今天下午3点左右摄像头歪曲照片</template>
            <img
              src="../../assets/images/visualize/title_icon.png"
              style="padding: 0"
            /> </a-tooltip
        ></span>
      </div>
    </div>
    <!--swiper-no-swiping 禁止拖动  -->
    <div
      class="swiper-container"
      id="swiper-container"
      v-if="swiper_list.length > 0"
    >
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, i) in swiper_list"
          :key="i"
          :data-href="sliteItem(item)"
          @click="handleClickSlide(item)"
        >
          <div class="swiper-slide-img">
            <img :src="pathUrl + item.ws_photo" alt="" />
            <!-- <img :src="item.ws_photo" alt="" /> -->
          </div>
          <div class="swiper-slide-img"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
import { GetWscamerabadpic } from "../../api/adminBoard";
import { urlConfig } from "@/config/config";
export default {
  name: "CameraDistortion",
  props: {
    bc_id: {},
    showDrawer: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      mySwiper: 0,
      swiper_list: [],
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      record: {},
      queryParam: { bc_id: "" },
      yesterdayStartTime: "",
      yesterdayEndTime: "",
    };
  },
  created() {
    this.getStartEndTime();
  },
  mounted() {
    // this.initSwiper(); //不能放在created里
    this.loadData();
    if (this.mySwiper != 0) {
      // this.$nextTick(() => {
      //   this.mySwiper.loopDestroy();
      //   this.mySwiper.loopCreate();
      // });
      this.$destory(this.mySwiper);
    }
  },

  watch: {
    bc_id(NV, OV) {
      this.queryParam.bc_id = NV;
      // console.log("this.queryParam", this.queryParam);
      this.loadData();
    },
  },
  methods: {
    getPopupContainer(trigger) {
      return trigger.parentElement;
    },
    initSwiper() {
      let vm = this;
      //   this.mySwiper = new Swiper("#swiper-container", {
      vm.mySwiper = new Swiper("#swiper-container", {
        // effect: "coverflow",
        // slidesOffsetBefore: 135, //偏移量
        centeredSlides: true,
        loop: true, // 循环模式选项 当播放到最后一张图片后，点击按钮是否会继续轮播
        initialSlide: 1,
        // direction:"vertical",// 垂直切换
        // slidesPerView: 2, //一次显示2个
        slidesPerView: 1, //一次显示2个

        // slidesPerGroup: 1, //一次往前移一个
        // spaceBetween: -80,
        spaceBetween: 5,
        // slidesPerColumn: 2, //多行 —— 一屏显示2行
        // loopedSlides: 1,
        // loopFillGroupWithBlank: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: true,
        autoplay: {
          //   delay: 1000 * 60, // 自动播放的间隔时间，单位ms，默认3000ms
          delay: 2000,
          disableOnInteraction: false, // 用户操作后是否停止自动播放，默认为true，即停止。改为false，表示不会停止自动播放
        },
        // width: 350,
        //设置断点宽度
        breakpoints: {
          // 1024: {
          //   width: 200,
          // },
          // 768: {
          //   width: 200, //取消width，恢复自适应
          // },
        },
        onSlideChangeEnd: function (swiper) {
          swiper.update();
          vm.mySwiper.startAutoplay();
          vm.mySwiper.reLoop();
        },
        on: {
          //   slideChangeTransitionEnd: function () {},
          //   click: function (swiper, event) {},
          // slideChangeTransitionStart: function () {
          //   let active = document
          //     .querySelector(".swiper-slide-active")
          //     .getAttribute("data-swiper-slide-index");
          //   // console.log(active);
          //   // console.log(this.realIndex);
          // },
          click: function (sw) {
            let sw_id = sw.target.src;
            // console.log(sw_id);
            // console.log(vm.swiper_list);
            vm.swiper_list.forEach((element) => {
              if (sw_id.indexOf(element.ws_photo) != -1) {
                // console.log("vm.record.ws_id", element);
                // vm.record.ws_id = element.ws_id;
                // vm.record.ws_name = element.ws_name;
                // vm.$emit("record", vm.record);
                // vm.showDrawer();
                // 跳转到整洁度管理页面 -昨日、摄像头歪曲
                localStorage.setItem("path", "SiteNeat");
                vm.$router.push({
                  name: "SiteNeat",
                  params: {
                    e_time_start: vm.yesterdayStartTime,
                    e_time_end: vm.yesterdayEndTime,
                    e_events: "4",
                    // bc_id: vm.bc_id,
                  },
                });
                // console.log(vm.$Format(vm.yesterdayStartTime));
              }
            });
            // console.log(sw_id);
          },
        },
      });
    },
    // 开启loop模式，拿不到第二轮首页数据
    handleClickSlide(index) {
      // console.log(index);
    },
    sliteItem(item) {
      //   console.log(item);//可以获取所有的数据
    },
    async loadData() {
      this.swiper_list = [];
      const orderParam = ["bc_id"];
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      await GetWscamerabadpic(params)
        .then((res) => {
          // console.log("摄像头歪曲", res.data);
          this.swiper_list = res.data;
          // console.log(this.swiper_list);
        })
        .then(() => {
          this.initSwiper(); //不能放在created里
        });
    },
    getStartEndTime(num = 1) {
      // 一天的毫秒数
      const MillisecondsADay = 24 * 60 * 60 * 1000 * num;
      // 今日开始时间戳
      const todayStartTime = new Date(
        new Date().setHours(0, 0, 0, 0)
      ).getTime();
      // 今日结束时间戳
      const todayEndTime = new Date(
        new Date().setHours(23, 59, 59, 999)
      ).getTime();

      // 昨日开始时间戳
      const yesterdayStartTime = todayStartTime - MillisecondsADay;
      // 昨日结束时间戳
      const yesterdayEndTime = todayEndTime - MillisecondsADay;
      this.yesterdayStartTime = yesterdayStartTime / 1000;
      this.yesterdayEndTime = yesterdayEndTime.toString().slice(0, 10) * 1;
      // console.log(this.yesterdayStartTime, this.yesterdayEndTime);
      return [yesterdayStartTime, yesterdayEndTime];
    },
  },
};
</script>

<style lang="less" scoped>
.CameraDistortion {
  width: 100%;
  height: 100%;
  .title {
    width: 100%;
    height: 15%;
    color: #66ffff;
    text-align: left;
    padding-left: 10px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    .title_days {
      width: 50%;
      // img {
      //   width: 8%;
      //   height: 40%;
      // }
      img {
        width: 13px;
        height: 13px;
      }
      .title_days_info {
        padding-left: 10px;
        cursor: pointer;
        /deep/.ant-tooltip-inner {
          width: 300px !important;
        }
      }
    }
  }
  .swiper-container {
    width: 100%;
    height: 85%;
    .swiper-slide {
      background-color: pink;
      .swiper-slide-img,
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}
</style>
