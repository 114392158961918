<template>
  <div class="BadManager">
    <!-- 整洁度差项目经理 -->
    <div class="title">
      <div class="title_days">
        整洁度差项目经理<span class="title_days_info">
          <a-tooltip
            placement="right"
            :get-popup-container="getPopupContainer"
            overlayClassName="popover"
          >
            <template slot="title"
              >统计今天前20名7:50整洁度差的项目经理</template
            >
            <img
              src="../../assets/images/visualize/title_icon.png"
              style="padding: 0"
            /> </a-tooltip
        ></span>
      </div>
      <div class="changeBtn">
        <!-- <span v-show="changeBtnShow">柱状图</span
        ><span v-show="!changeBtnShow">轮播图</span> -->
        <img
          v-show="!changeBtnShow"
          src="../../assets/images/adminBoard/01-1.png"
          alt=""
          @click="isChangeBtn(true)"
        />
        <img
          v-show="changeBtnShow"
          src="../../assets/images/adminBoard/01-2.png"
          alt=""
        />
        <!-- @click="isChangeBtn(false)" -->
        <img
          v-show="changeBtnShow"
          src="../../assets/images/adminBoard/02-1.png"
          alt=""
          @click="isChangeBtn(false)"
        />

        <img
          v-show="!changeBtnShow"
          src="../../assets/images/adminBoard/02-2.png"
          alt=""
        />
        <!-- @click="isChangeBtn(true)" -->
      </div>
    </div>
    <!--swiper-no-swiping 禁止拖动  -->
    <div class="swiper-container" :id="currentIndex" v-if="changeBtnShow">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, i) in swiper_list"
          :key="i"
          :id="item.slider"
        >
          <div class="swiper-slide-img">
            <div v-if="item.w_photo == null" class="noPhoto">暂无图片</div>
            <div v-else class="img">
              <img :src="pathUrl + item.w_photo" alt="" />
            </div>
          </div>
          <div class="swiper-slide-name">
            <a-tooltip placement="bottomLeft">
              <template slot="title">
                <span> {{ item.w_name }}</span>
              </template>
              {{ item.w_name }}
            </a-tooltip>
          </div>
        </div>
      </div>
    </div>

    <!-- <div id="BadManager" v-if="!changeBtnShow"></div> -->
    <div class="BadManagerData" v-if="!changeBtnShow">
      <BadManagerData :bc_id="bc_id" />
    </div>
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
import { urlConfig } from "@/config/config";
import { GetBadsitejlworker } from "../../api/adminBoard";
import * as echarts from "echarts";
import BadManagerData from "./BadManagerData.vue";
export default {
  name: "BadManager",
  components: {
    BadManagerData,
  },
  props: {
    bc_id: {},
  },
  data() {
    return {
      currentIndex: 1,
      queryParam: { bc_id: "" },
      // 轮播
      swiper_list: [
        // { w_photo: require("../data/datav.jpg"), w_name: "name" },
        // { w_photo: require("../data/inspection.png"), w_name: "name" },
        // { w_photo: require("../data/caseField.png"), w_name: "name" },
        // { w_photo: require("../data/datav.png"), w_name: "name" },
      ],

      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      barTimer: null,
      changeBtnShow: true,
    };
  },
  created() {},
  mounted() {
    this.loadData();
    // this.initSwiper();
  },
  watch: {
    bc_id(NValue, OValue) {
      this.queryParam.bc_id = NValue;
      this.loadData();
      // console.log(this.queryParam.bc_id);
    },
  },
  methods: {
    getPopupContainer(trigger) {
      return trigger.parentElement;
    },
    initSwiper() {
      let vm = this;
      vm.mySwiper = new Swiper("#" + vm.currentIndex, {
        loop: true, // 循环模式选项 当播放到最后一张图片后，点击按钮是否会继续轮播
        slidesPerView: 4, //一次显示4个
        // slidesPerView: 5, //一次显示10个
        slidesPerGroup: 1, //一次往前移一个
        spaceBetween: 5,
        // slidesPerColumn: 2, //多行 —— 一屏显示2行
        // loopedSlides: 1,
        // loopFillGroupWithBlank: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: false, //修改swiper的父元素时，自动初始化swiper
        autoplay: true,
        autoplay: {
          //   delay: 1000 * 60, // 自动播放的间隔时间，单位ms，默认3000ms
          delay: 2000,
          disableOnInteraction: false, // 用户操作后是否停止自动播放，默认为true，即停止。改为false，表示不会停止自动播放
        },
        // 如果需要前进后退按钮
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        // 如果需要分页器 即轮播图下面的小圆点
        // pagination: {
        //   el: ".swiper-pagination",
        // },
        onSlideChangeEnd: function (swiper) {
          swiper.update();
          vm.mySwiper.startAutoplay();
          vm.mySwiper.reLoop();
        },
        on: {
          // slideChangeTransitionEnd: function () {
          //   // vm.currentIndex;
          //   // console.log(vm.currentIndex);
          // },
          click: function (sw) {
            let target = sw.target;
            // console.log(target);
            vm.swiper_list.forEach((el) => {
              // if (
              //   target.innerText.indexOf(el.w_name) != -1 ||
              //   target.src.indexOf(el.w_photo) != -1
              // ) {
              //   // console.log(el.si_eventworker);
              //   // console.log(el);
              //   // 跳转到报警事件列表页面
              //   localStorage.setItem("path", "SiteNeat");
              //   vm.$router.push({
              //     path: "/SiteNeat",
              //     query: {
              //       e_events: "3",
              //       w_name: el.w_name,
              //       bc_id: vm.queryParam.bc_id,
              //     },
              //   });
              // }

              if (target.innerText.indexOf(el.w_name) != -1) {
                // 跳转到报警事件列表页面
                localStorage.setItem("path", "SiteNeat");
                vm.$router.push({
                  path: "/SiteNeat",
                  query: {
                    e_events: "3",
                    w_name_jl: el.w_name,
                    bc_id: vm.queryParam.bc_id,
                  },
                });
              }
              if (target.src && target.src.indexOf(el.w_photo) != -1) {
                // 跳转到报警事件列表页面
                localStorage.setItem("path", "SiteNeat");
                vm.$router.push({
                  path: "/SiteNeat",
                  query: {
                    e_events: "3",
                    w_name_jl: el.w_name,
                    bc_id: vm.queryParam.bc_id,
                  },
                });
              }
            });
          },
        },
      });
    },
    async loadData() {
      // this.swiper_list = [];
      const orderParam = ["bc_id"];
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      await GetBadsitejlworker(params)
        .then((res) => {
          // console.log("整洁度差项目经理", res.data);
          this.swiper_list = res.data;
        })
        .then(() => {
          // 绘制轮播图
          this.initSwiper(); //不能放在created里
        });
    },

    // 切换按钮
    isChangeBtn(text) {
      // this.changeBtnShow = !this.changeBtnShow;
      this.changeBtnShow = text;
      if (this.changeBtnShow) {
        this.loadData();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.BadManager {
  width: 100%;
  height: 100%;
  .title {
    width: 100%;
    height: 15%;
    color: #66ffff;
    text-align: left;
    padding-left: 10px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    .title_days {
      width: 50%;
      img {
        width: 12px;
        height: 12px;
      }
      // img {
      //   width: 8%;
      //   height: 40%;
      // }
      .title_days_info {
        padding-left: 10px;
        cursor: pointer;
        /deep/.ant-tooltip-inner {
          width: 300px !important;
        }
      }
    }
    .changeBtn {
      cursor: pointer;
      font-size: 12px;
      img {
        width: 15px;
        height: 15px;
        margin: 5px;
        margin-left: 0;
      }
    }
  }
  .swiper-container {
    width: 100%;
    height: 85%;
    .swiper-slide {
      // background-color: pink;
      cursor: pointer;
      .swiper-slide-img {
        width: 100%;
        height: 80%;
        // background-color: #fff;
        .noPhoto {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .img {
          height: 100%;
          width: 100%;
        }
        img {
          height: 100%;
          width: 100%;
        }
      }
      .swiper-slide-name {
        width: 100%;
        height: 20%;
        // background-color: #fff;
        color: #d5f3f3;
        display: flex;
        justify-content: center;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  #BadManager {
    height: 86%;
    width: 100%;
    // top: -10%;
    // background-color: pink;
  }
  .BadManagerData {
    width: 100%;
    height: 100%;
  }
}
</style>
