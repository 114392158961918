<template>
  <div class="GoodPhoto">
    <!-- 整洁度差工地快照 -->
    <div class="title">
      <div class="title_days">整洁度优工地快照</div>
    </div>
    <!--swiper-no-swiping 禁止拖动  -->
    <div
      class="swiper-container"
      id="swiper-container-GoodPhoto"
      v-if="swiper_list.length > 0"
    >
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, i) in swiper_list"
          :key="i"
          :data-href="sliteItem(item)"
          @click="handleClickSlide(item)"
        >
          <!-- :id="i" -->
          <div class="swiper-slide-img">
            <img :src="pathUrl + item.ws_photo" alt="" />
          </div>
          <!-- <div class="swiper-slide-img"></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
import { GetWscleanbadpic, GetWscleangoodpic } from "../../api/adminBoard";
import { urlConfig } from "@/config/config";
export default {
  name: "GoodPhoto",
  props: {
    bc_id: {},
    showDrawer: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      mySwiper: "",
      swiper_list: [
        // { ws_photo: require("../data/datav.jpg") },
        // { ws_photo: require("../data/inspection.png") },
        // { ws_photo: require("../data/caseField.png") },
        // { ws_photo: require("../data/datav.png") },
      ],
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      record: {},
      queryParam: { bc_id: "" },
    };
  },
  created() {},
  mounted() {
    this.loadData();
  },
  updated() {
    if (this.mySwiper) {
      this.$nextTick(() => {
        this.mySwiper.loopDestroy();
        this.mySwiper.loopCreate();
      });
    }
  },
  watch: {
    bc_id(NValue, OValue) {
      this.queryParam.bc_id = NValue;
      this.loadData();
      // console.log(this.queryParam.bc_id);
    },
  },
  methods: {
    initSwiper() {
      let vm = this;
      //   this.mySwiper = new Swiper("#swiper-container", {
      this.mySwiper = new Swiper("#swiper-container-GoodPhoto", {
        // effect: "coverflow",
        // slidesOffsetBefore: 20, //偏移量

        centeredSlides: true,
        loop: true, // 循环模式选项 当播放到最后一张图片后，点击按钮是否会继续轮播
        // initialSlide: 1,
        // direction:"vertical",// 垂直切换
        slidesPerView: 1, //一次显示2个
        // slidesPerGroup: 1, //一次往前移一个
        spaceBetween: 1,
        // slidesPerColumn: 2, //多行 —— 一屏显示2行
        // loopedSlides: 1,
        // loopFillGroupWithBlank: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: true,
        autoplay: {
          //   delay: 1000 * 60, // 自动播放的间隔时间，单位ms，默认3000ms
          delay: 2000,
          disableOnInteraction: false, // 用户操作后是否停止自动播放，默认为true，即停止。改为false，表示不会停止自动播放
        },
        // width: 350,
        //设置断点宽度
        breakpoints: {
          // 1024: {
          //   width: 200,
          // },
          768: {
            // width: 290, //取消width，恢复自适应
          },
        },
        on: {
          //   slideChangeTransitionEnd: function () {},
          //   click: function (swiper, event) {},
          // slideChangeTransitionStart: function () {
          //   let active = document
          //     .querySelector(".swiper-slide-active")
          //     .getAttribute("data-swiper-slide-index");
          //   // console.log(active);
          //   // console.log(this.realIndex);
          // },
          click: function (sw) {
            let sw_id = sw.target.src;
            // console.log("整洁度差工地快照", sw_id);
            vm.swiper_list.forEach((element) => {
              if (sw_id.indexOf(element.ws_photo) != -1) {
                vm.record.ws_id = element.ws_id;
                vm.record.ws_name = element.ws_name;
                console.log("vm.record.ws_id", vm.element);
                vm.$emit("record", vm.record);
                vm.showDrawer();
              }
            });
          },
        },
      });
    },
    // 开启loop模式，拿不到第二轮首页数据
    handleClickSlide(index) {
      // console.log(index);
    },
    sliteItem(item) {
      //   console.log(item);//可以获取所有的数据
    },
    async loadData() {
      this.swiper_list = [];
      const orderParam = ["bc_id"];
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      await GetWscleangoodpic(params)
        .then((res) => {
          // console.log("整洁度差工地快照", res);
          this.swiper_list = res.data;
        })
        .then(() => {
          this.initSwiper(); //不能放在created里
        });
    },
  },
};
</script>

<style lang="less" scoped>
.GoodPhoto {
  width: 100%;
  height: 100%;
  .title {
    width: 100%;
    height: 15%;
    color: #66ffff;
    text-align: left;
    padding-left: 10px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    .title_days {
      width: 50%;
      img {
        width: 8%;
        height: 40%;
      }
    }
  }
  .swiper-container {
    width: 100%;
    height: 85%;
    .swiper-slide {
      background-color: pink;
      .swiper-slide-img,
      img {
        height: 100%;
        width: 100%;
        cursor: pointer;
      }
    }
  }
  // 如果想要两边的图片显示时缩小加上一下css代码
  // 默认中间的图片会带有类名 : .swiper-slide-acive;
  // 默认中间的图片的上一张图片会带有类 名:  .swiper-slide-prev ;
  // 默认中间的图片的下一张会带有类名: .swiper-slide-next ;.
  // .swiper-slide-next {
  //   transform: scale(0.8);
  // }
  // .swiper-slide-prev {
  //   transform: scale(0.8);
  // }
}
</style>
