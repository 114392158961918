<template>
  <!-- <div class="OnlinerateManagerData"> -->
  <!-- 在线率低的项目经理数据统计图 -->
  <div id="OnlinerateManagerData"></div>
  <!-- </div> -->
</template>

<script>
import { urlConfig } from "@/config/config";
import { GetBadsitejlworker, GetLowonlinejl } from "../../api/adminBoard";
import * as echarts from "echarts";
export default {
  name: "OnlinerateManagerData",
  props: {
    bc_id: {},
  },
  data() {
    return {
      queryParam: { bc_id: "" },

      // 柱状图
      option: {
        color: "#100C2A",
        tooltip: {
          trigger: "item",
          formatter: "{c}个工地", //{b}：name; {c}:value
        },
        grid: {
          // x: 0,
          y: "14%",
          right: "8%",
          left: "22%",
          bottom: "5%", //默认60
        },
        yAxis: {
          type: "category",
          data: [],
          // name: "天",
          nameTextStyle: {
            // x轴name的样式调整

            fontSize: 12,
          },
          // 文字省略
          triggerEvent: true,
          axisLabel: {
            color: "#ccc",
            // color: "#4c9bfd", // 文字颜色
            // rotate: 10, // 文字倾斜
            // 文字省略
            formatter: function (value) {
              if (value.length > 3) {
                return `${value.slice(0, 3)}...`;
              }
              return value;
            },
          },

          // boundaryGap: false, //坐标轴两边不留白
          // nameRotate: 90, // y轴name旋转90度 使其垂直
          // nameGap: 5, // x轴name与横坐标轴线的间距
          inverse: true,
          animationDuration: 1000,
          animationDurationUpdate: 1000,
          // max: 3, // only the largest 3 bars will be displayed
        },
        xAxis: {
          // type: "value",
          // name: "工地数",
          // nameGap: 10, // x轴name与横坐标轴线的间距
          // min: 0,
          // max: 200,
          // position: "left",
          // length: 7,
          axisTick: {
            show: false, //刻度线
          },
          axisLine: {
            show: false, //隐藏y轴
          },
          axisLabel: {
            show: false, //隐藏刻度值
          },

          splitLine: {
            lineStyle: {
              // 设置背景横线
              color: "#807a7a83",
            },
          },
        },
        dataZoom: [
          {
            yAxisIndex: 0, // 这里是从y轴的0刻度开始
            show: false, // 是否显示滑动条，不影响使用
            type: "slider", // 这个 dataZoom 组件是 slider 型 dataZoom 组件
            startValue: 0, // 从头开始。
            endValue: 1, // 一次性展示多少个。
          },
        ],

        series: [
          {
            data: [],
            // realtimeSort: true,
            name: "昨日已绑定数量",
            type: "bar",
            color: "#386ABF",
            // smooth: true, //将折线变成平滑的线条，不是直线
            label: {
              show: true, //内部显示数值
              // position: "right", // outside外部显示  inside 内部显示
              formatter: `{c}`,
              // valueAnimation: true,
            },
            // itemStyle: {
            //   normal: {
            //     color: new echarts.graphic.LinearGradient(1, 0, 0, 0, [
            //       {
            //         offset: 0,
            //         color: "#386ABF",
            //       }, // 0% 处的颜色
            //       {
            //         offset: 0.4,
            //         color: "#386ABF",
            //       }, // 100% 处的颜色
            //       {
            //         offset: 1,
            //         color: "#000",
            //       }, // 100% 处的颜色
            //     ]),
            //   },
            // },
          },
          {
            // data: [700, 900, 1000, 900, 1100, 900, 1501],
            data: [
              800, 1000, 900, 900, 1300, 1400, 1411, 1300, 1400, 1411, 1300,
              1400, 1411,
            ],
            // realtimeSort: true,
            name: "昨日在线≤2h",
            type: "bar",
            color: "#7CFFB2",
            // smooth: true, //将折线变成平滑的线条，不是直线
            label: {
              show: true, //内部显示数值
              // position: "right", // outside外部显示  inside 内部显示
              formatter: `{c}`,
              valueAnimation: true,
            },
          },
        ],
        legend: {
          show: true,
          data: ["昨日已绑定数量", "昨日在线≤2h"],
          // icon: "rect",
          width: "300px",
          top: "-3%",
          textStyle: {
            color: "#ccc",
            // ...
          },
        },
        animationDurationUpdate: 1000,
        animationEasing: "linear",
        animationEasingUpdate: "linear",
      },
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      barTimer: null,
      changeBtnShow: true,
    };
  },
  created() {},
  mounted() {
    // this.setOption();
    this.loadData();

    // // 页面监控宽度的变化
    // window.addEventListener("resize", function () {
    //   myChart.resize();
    // });
  },
  watch: {
    bc_id(NValue, OValue) {
      this.queryParam.bc_id = NValue;
      this.loadData();
      console.log(NValue);
      // console.log(this.queryParam.bc_id);
    },
  },
  methods: {
    async loadData() {
      // this.swiper_list = [];
      const orderParam = ["bc_id"];
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      await GetLowonlinejl(params)
        .then((res) => {
          // console.log("在线率低", res.data);
          let resData = res.data;
          let w_name = [];
          let ws_sum = [];
          let ws_lowonline = [];
          resData.forEach((element) => {
            // w_name.push(element.w_name.replace(/\（[^)]*\）/g, ""));
            w_name.push(element.w_name);
            ws_sum.push(element.ws_sum);
            ws_lowonline.push(element.ws_lowonline);
          });

          this.option.yAxis.data = w_name;
          this.option.series[0].data = ws_sum;
          this.option.series[1].data = ws_lowonline;
        })
        .then(() => {
          // 绘制轮播图
          this.setOption();
        });
    },
    // 绘制图表
    setOption() {
      var myChart = echarts.init(
        document.getElementById("OnlinerateManagerData")
      );
      // 绘制图表
      setTimeout(() => {
        myChart.setOption(this.option);
        // myChart.on("click", function (params) {});
        let vm = this;
        this.barTimer = setInterval(function () {
          // 定时自动滚动

          // vm.option.series[0].data 为x轴数据
          if (
            vm.option.dataZoom[0].endValue == vm.option.series[0].data.length
          ) {
            vm.option.dataZoom[0].endValue = 1;
            vm.option.dataZoom[0].startValue = 0;
          } else {
            vm.option.dataZoom[0].endValue = vm.option.dataZoom[0].endValue + 1;
            vm.option.dataZoom[0].startValue =
              vm.option.dataZoom[0].startValue + 1;
            // vm.option.dataZoom[0].endValue = 3;
            // vm.option.dataZoom[0].startValue = 0;
          }
          // console.log(vm.option.series[0].data.length);
          // console.log(vm.option.dataZoom[0].endValue);
          myChart.setOption(vm.option);
        }, 2000);
      }, 10);
      this.extension(myChart);
    },

    extension(myChart) {
      // 注意这里，是以X轴显示内容过长为例，如果是y轴的话，需要把params.componentType == 'xAxis'改为yAxis
      // 判断是否创建过div框,如果创建过就不再创建了
      // 该div用来盛放文本显示内容的，方便对其悬浮位置进行处理
      var elementDiv = document.getElementById("extension");
      if (!elementDiv) {
        var div = document.createElement("div");
        div.setAttribute("id", "extension");
        div.style.display = "block";
        document.querySelector("html").appendChild(div);
      }
      myChart.on("mouseover", function (params) {
        if (params.componentType == "yAxis") {
          var elementDiv = document.querySelector("#extension");
          //设置悬浮文本的位置以及样式
          var elementStyle =
            "position: absolute;z-index: 99999;color: #fff;font-size: 12px;padding: 5px;display: inline;border-radius: 4px;background-color: #303133;box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 8px;margin-top:5px";
          elementDiv.style.cssText = elementStyle;
          elementDiv.innerHTML = params.value;
          document.querySelector("html").onmousemove = function (event) {
            var elementDiv = document.querySelector("#extension");
            var xx = event.pageX - 10;
            var yy = event.pageY + 15;
            elementDiv.style.top = yy + "px";
            elementDiv.style.left = xx + "px";
          };
        }
      });
      myChart.on("mouseout", function (params) {
        //注意这里，我是以X轴显示内容过长为例，如果是y轴的话，需要改为yAxis
        if (params.componentType == "yAxis") {
          var elementDiv = document.querySelector("#extension");
          elementDiv.style.cssText = "display:none";
        }
      });
      myChart.on("click", function (params) {
        console.log(params);
      });
    },
  },

  beforeDestroy() {
    clearInterval(this.barTimer);
  },
};
</script>

<style lang="less" scoped>
#OnlinerateManagerData {
  height: 86%;
  width: 100%;
  // top: -10%;
  // background-color: pink;
}
</style>
