<!-- 直播 -->
<template>
  <div>
    <div
      :class="
        pageType === 'patrol'
          ? 'modal-content-nobg'
          : 'modal-content  modal-content1 myVideo_out'
      "
    >
      <video
        autoplay
        controls
        width="100%"
        height="100%"
        id="myVideoAdmine"
        crossorigin="*"
        style="background: black; object-fit: cover"
        :poster="posterImg"
      ></video>
    </div>
  </div>
</template>

<script>
import {
  GetSiteVideo,
  StopSiteVideo,
  OnCall,
  OffCall,
  CameraMove,
  CameraReset,
  CameraRestart,
  CameraStatus,
  videoSignal,
  getSitPreset,
} from "@/api/video";
// import { SetSampleSite } from "@/api/site";
import flvjs from "flv.js";
// import LineImage from "../datav/LineImage.vue";
// import SiteHistoryRecord from "../site/SiteHistoryRecord.vue";
import { urlConfig } from "@/config/config";

export default {
  components: {
    // LineImage,
    // SiteHistoryRecord,
  },
  name: "AdminePreview", // 直播
  props: {
    record: {
      type: Object,
      default: {
        ws_name: "",
        ws_model: "",
      },
    },
    pageType: {
      type: String,
      default: "",
    },
    pageTypecaseField: {
      type: String,
      default: "",
    },
    SiteList_previer_true: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
    },
    isPreviewShow: {
      type: Boolean,
    },
  },
  ws: null,
  soundrecord: null,
  data() {
    return {
      timer: null,
      bc_id: "",
      ws_id: "",
      video: {
        com_only: "",
        video_url: "",
        audio_url: "",
        stream_id: "",
        push_stream_id: "",
      },
      voiceCall: false,
      cycleMax: 15,
      cycleTime: 1,
      flvPlayer: null,
      // 弹出框
      visible: false,
      confirmLoading: false,
      wsModel: "0",
      type: "2",
      typecaseField: "2",
      ModelcaseField: "2",
      // stream_url: "webrtc://dsm.wisecommey.com:1990/stream/",
      stream_url: "webrtc://dsm.ccom.com.cn:1990/stream/",

      // "wss://dsm..ccom.com.cn:8443/ws/asset/" + that.video.push_stream_id

      Video_Status: "",
      xinhao: null,
      NoXinhao: false,
      jingyin: 1,
      bofang: 2,
      signalResData2: "",
      getvideoSignalTimer: null,
      // SiteHistoryRecord: false,
      ModalText: "Content of the modal",
      visibleHistory: false,
      confirmLoadingHistory: false,
      recordwsid: "",
      // 图片地址
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      posterImg: "",
    };
  },
  created() {
    if (this.record.ws_id) {
      this.ws_id = this.record.ws_id;
      console.log(this.ws_id);
      // this.bc_id = this.record.bc_id;
      this.wsModel = this.record.ws_model;
      this.type = this.record.ws_model;
      this.getVideo();
    }
    // // console.log(this.ws_id);
    // setTimeout(() => {
    //   this.wsModel = this.record.ws_model;
    //   this.type = this.record.ws_model;
    //   // 获取摄像头状态
    //   // this.videoStatus("cycle");
    // }, 1000);
    // if (this.record.ws_id != "") {
    //   // // 拉流;
    //   var videoElement = document.getElementById("myVideoAdmine");
    //   this.startPlay(this.stream_url + this.video.stream_id, videoElement);
    //   // // 推流
    //   this.startPublish(
    //     this.stream_url + this.video.push_stream_id,
    //     new Audio()
    //   );
    // }
  },
  mounted() {
    // 进入页面默认静音
    // this.handelPlay("jingyin");
    window.onunload = function (e) {
      window.clearTimeout(this.timer); // 清理定时器
      this.offCall();
      this.stopVideo();
    };
  },
  watch: {
    // show(newValue, oldValue) {
    //   if (newValue == true) {
    //     this.getVideo();
    //   } else if (newValue == false) {
    //     this.stopVideo();
    //   }
    // },
    // record(record) {
    //   this.ws_id = record.ws_id;
    //   this.getVideo();
    // },
    isPreviewShow(newValue, oldValue) {
      // if (newValue == true) {
      //   this.getVideo();
      // } else if (newValue == false) {
      if (newValue == false) {
        this.stopVideo();
      }
    },
  },
  methods: {
    // 查询视频推流、拉流地址
    getVideo() {
      const params = {
        ws_id: this.ws_id,
        // ws_id: this.record.d_ws_id,
      };
      GetSiteVideo(params).then((res) => {
        if (res.code == 200) {
          // this.video.com_only=res.data.com_only
          this.posterImg = this.pathUrl + res.data.file_path;
          this.video = res.data;
          const url = res.data.video_url;
          const push_url = res.data.audio_url;
          this.video.stream_id = url.substring(url.lastIndexOf("/") + 1);
          this.video.push_stream_id = push_url.substring(
            push_url.lastIndexOf("/") + 1
          );
          // 拉流
          this.videoPlayer();
          // 推流
          // this.talkStartTest();
          this.startPublish(
            this.stream_url + this.video.push_stream_id,
            new Audio()
          );

          // // 获取摄像头状态
          // this.videoStatus("cycle");
        } else {
          // this.$message.error(res.data);
          if (res.data == "摄像头状态为：4") {
            this.$message.error("自动挂断");
          }
        }
      });
    },

    // 每隔1秒检测一次摄像头状态
    checkStatus() {
      let that = this;
      if (that.cycleTime < that.cycleMax) {
        that.timer = setTimeout(() => {
          that.videoStatus("cycle");
          that.cycleTime++;
        }, 1000);
      } else {
        that.$message.warn("直播查看超时");
        this.stopVideo();
      }
    },
    // 检测视频流状态
    videoStatus(type) {
      const params = {
        ws_id: this.ws_id,
        com_only: this.video.com_only,
      };
      CameraStatus(params).then((res) => {
        this.Video_Status = res.data;
        // console.log("检测摄像头状态", this.Video_Status);
        if (res.data === "2") {
          window.clearTimeout(this.timer); // 清理定时器
          // 拉流
          // this.videoPlayer();
          //开始推流
          // this.talkStartTest();
          // console.log('this.video.push_stream_id', this.video.push_stream_id)
          // this.startPublish(
          //   this.stream_url + this.video.push_stream_id,
          //   new Audio()
          // );
        } else {
          if (type !== "cycle") {
            this.$message.error("视频加载中");
          } else {
            this.checkStatus();
          }
        }
      });
    },
    // flv拉流播放视频
    videoPlayer() {
      var videoElement = document.getElementById("myVideoAdmine");
      this.startPlay(this.stream_url + this.video.stream_id, videoElement);
      // if (flvjs.isSupported()) {
      if (false) {
        var videoElement = document.getElementById("myVideoAdmine");
        this.flvPlayer = flvjs.createPlayer({
          type: "flv",
          // url: 'https://dsm.wisecommey.com:8843/live?app=stream&stream=' + this.video.stream_id,
          url:
            // "https://dsm.wisecommey.com:8843/stream/"
            "https://dsm.ccom.com.cn:8843/stream/" +
            this.video.stream_id +
            ".flv",
          // url: 'http://123.56.117.58:8080/live/123456',
        });
        this.flvPlayer.attachMediaElement(videoElement); //将播放实例注册到video节点
        this.flvPlayer.load();
        this.flvPlayer.play();
        this.flvPlayer.on(
          flvjs.Events.ERROR,
          (errorType, errorDetail, errorInfo) => {
            console.log("errorType:", errorType);
            console.log("errorDetail:", errorDetail);
            console.log("errorInfo:", errorInfo);
          }
        );
        this.flvPlayer.on(flvjs.Events.STATISTICS_INFO, function (info) {
          console.log(
            "STATISTICS_INFO:",
            info,
            videoElement.currentTime,
            videoElement.readyState
          );
          if (info.speed !== 0) {
            if (this.decodedFrames === info.decodedFrames) {
              this.count++;
            } else {
              this.count = 0;
            }
            if (this.count == 5) {
              console.log("卡住了", videoElement.currentTime);
              videoElement.currentTime = videoElement.buffered.end(0) - 0.2;
            }
            this.decodedFrames = info.decodedFrames;
          }
        });

        this.timeout = setInterval(() => {
          if (videoElement.buffered.length) {
            let end = videoElement.buffered.end(0);
            let diff = end - videoElement.currentTime;
            console.log("diff:", diff);
            if (diff >= 4.0) {
              videoElement.currentTime = videoElement.buffered.end(0) - 0.2;
              console.log("跳帧");
            } else if (diff >= 2.0) {
              if (videoElement.playbackRate === 1.0) {
                videoElement.playbackRate = 1.5; // 采用加速方式追帧
                console.log("追帧(1.5倍速)");
              }
            } else {
              if (videoElement.playbackRate !== 1.0) {
                videoElement.playbackRate = 1.0;
                console.log("1.0速度");
              }
            }
          }
        }, 2000);
      }
    },
    startPlay(url, video) {
      this.player = new SrsRtcPlayerAsync(video);
      // https://webrtc.org/getting-started/remote-streams
      // Optional callback, SDK will add track to stream.
      // sdk.ontrack = function (event) { console.log('Got track', event); sdk.stream.addTrack(event.track); };
      // For example: webrtc://r.ossrs.net/live/livestream
      this.player
        .play(url)
        .then(function (session) {
          // console.log("sessionid", session.simulator, session.sessionid);
        })
        .catch(function (reason) {
          this.player.close();
          console.error(reason);
        })
        .finally(() => {});
      // console.log(this.player);
    },
    // 关闭
    close() {
      window.clearTimeout(this.timer); // 清理定时器
      this.offCall();
      this.stopVideo();
    },
    // 推流
    //方法未使用
    pushVideo() {
      window.navigator.webkitGetUserMedia(); //调用本地摄像头麦克风
    },
    stopVideo() {
      const params = {
        ws_id: this.ws_id,
        com_only: this.video.com_only,
      };
      // this.talkEndTest(); //断开通话
      if (this.publisher) {
        this.publisher.close();
      }
      if (this.flvPlayer) {
        clearInterval(this.timeout);
        this.flvPlayer.unload(); //取消数据流加载
        this.flvPlayer.detachMediaElement(); //将播放实例从节点中取出
        this.flvPlayer.destroy(); //销毁播放实例
        this.flvPlayer = null;
      }
      StopSiteVideo(params)
        .then((res) => {
          // this.$message.success("断开视频成功");
        })
        .catch(() => {
          // this.$message.error("断开视频失败");
        });
    },
    handleCall() {
      this.voiceCall = !this.voiceCall;
      if (this.voiceCall) {
        this.onCall();
      } else {
        this.offCall();
      }
    },
    // // 通话
    // onCall() {
    //   const params = {
    //     ws_id: this.ws_id,
    //     com_only: this.video.com_only,
    //   };
    //   OnCall(params).then((res) => {
    //     console.log(res);
    //     if (res.code == "200" && res.data == "通话链接成功") {
    //       var videoElement = document.getElementById("myVideoAdmine");
    //       // videoElement.muted = true;
    //       this.voiceCall = true;
    //       this.$message.success("通话中");
    //     } else {
    //       this.$message.success("通话忙");
    //     }
    //   });
    // },
    // // 通话结束
    offCall() {
      const params = {
        ws_id: this.ws_id,
        com_only: this.video.com_only,
      };
      OffCall(params).then((res) => {
        var videoElement = document.getElementById("myVideoAdmine");
        // videoElement.muted = false;
        this.voiceCall = false;
        // this.$message.success("通话结束");
      });
    },
    // // 移动摄像机位置
    // moveCamera(direction, directionCH) {
    //   const params = {
    //     ws_id: this.ws_id,
    //     com_only: this.video.com_only,
    //     direction: direction,
    //   };
    //   CameraMove(params).then((res) => {
    //     // this.$message.success("向" + directionCH + res.data);
    //   });
    // },

    handleRestart() {
      this.visible = true;
    },
    restartCancel() {
      this.visible = false;
    },
    // 重启摄像头
    // restart() {
    //   this.confirmLoading = true;
    //   const params = {
    //     ws_id: this.ws_id,
    //     com_only: this.video.com_only,
    //   };
    //   CameraRestart(params)
    //     .then((res) => {
    //       this.$message.success("重启摄像头成功");
    //       this.confirmLoading = false;
    //       this.visible = false;
    //     })
    //     .catch(() => {
    //       this.$message.error("重启摄像头失败");
    //       this.confirmLoading = false;
    //     });
    // },
    onPlayerPlay() {},
    onPlayerPause() {},
    //屏幕截图
    screenshot() {
      var canvas = document.getElementById("screenshot");
      if (!canvas.getContext) {
        alert("您的浏览器暂不支持canvas");
        return false;
      } else {
        var context = canvas.getContext("2d");
        var video = document.getElementById("myVideoAdmine");
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        var mySrc = canvas.toDataURL("image/png");
      }
      var alink = document.createElement("a");
      alink.href = mySrc;
      alink.download = "jietu.png"; //图片名
      alink.click();
    },

    //对讲-语音推流
    init(rec) {
      this.soundrecord = rec;
    },
    talkStartTest1() {
      let that = this;
      navigator.getUserMedia =
        navigator.getUserMedia || navigator.webkitGetUserMedia;
      if (!navigator.getUserMedia) {
        alert("浏览器不支持音频输入");
      } else {
        navigator.getUserMedia(
          {
            audio: true,
          },
          function (mediaStream) {
            that.init(new that.Recorder(mediaStream, that));
            console.log("开始对讲");
            that.useWebSocket();
          },
          function (error) {
            console.log(error);
            switch (error.message || error.name) {
              case "PERMISSION_DENIED":
              case "PermissionDeniedError":
                console.info("用户拒绝提供信息。");
                break;
              case "NOT_SUPPORTED_ERROR":
              case "NotSupportedError":
                console.info("浏览器不支持硬件设备。");
                break;
              case "MANDATORY_UNSATISFIED_ERROR":
              case "MandatoryUnsatisfiedError":
                console.info("无法发现指定的硬件设备。");
                break;
              default:
                console.info(
                  "无法打开麦克风。异常信息:" + (error.code || error.name)
                );
                break;
            }
          }
        );
      }
    },
    // 新版使用方法
    talkStartTest() {
      // 老的浏览器可能根本没有实现 mediaDevices，所以我们可以先设置一个空的对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // 一些浏览器部分支持 mediaDevices。我们不能直接给对象设置 getUserMedia
      // 因为这样可能会覆盖已有的属性。这里我们只会在没有getUserMedia属性的时候添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先，如果有getUserMedia的话，就获得它
          var getUserMedia =
            navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

          // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
          if (!getUserMedia) {
            return Promise.reject(new Error("浏览器不支持getUserMedia"));
          }

          // 否则，为老的navigator.getUserMedia方法包裹一个Promise
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }

      let that = this;
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(function (stream) {
          that.init(new that.Recorder(stream, that));
          console.log("开始对讲");
          that.useWebSocket();
        })
        .catch(function (err) {
          // console.log(err.name + ": " + err.message);
        });
    },
    // 关闭对讲
    talkEndTest() {
      if (this.ws) {
        this.ws.close();
        this.soundrecord.stop();
        console.log("关闭对讲以及WebSocket");
      }
    },
    // 推流
    startPublish(url, audio) {
      this.publisher = new SrsRtcPublisherAsync(audio);
      // console.log('startPublish', this.publisher)
      const publisher = this.publisher;
      this.publisher.pc.onicegatheringstatechange = function (event) {
        if (publisher.pc.iceGatheringState === "complete") {
          //  $('#acodecs').html(SrsRtcFormatSenders(sdk.pc.getSenders(), "audio"))
          //  $('#vcodecs').html(SrsRtcFormatSenders(sdk.pc.getSenders(), "video"))
        }
      };
      this.publisher
        .publish(url)
        .then(function (session) {
          console.log("sessionid", session.simulator, session.sessionid);
          publisher.mute();
        })
        .catch(function (reason) {
          // Throw by sdk.
          if (reason instanceof SrsError) {
            if (reason.name === "HttpsRequiredError") {
              alert(
                `WebRTC推流必须是HTTPS或者localhost：${reason.name} ${reason.message}`
              );
            } else {
              alert(`${reason.name} ${reason.message}`);
            }
          }
          // See https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
          if (reason instanceof DOMException) {
            if (reason.name === "NotFoundError") {
              // alert(
              //   `找不到麦克风和摄像头设备：getUserMedia ${reason.name} ${reason.message}`
              // );
            } else if (reason.name === "NotAllowedError") {
              alert(
                `你禁止了网页访问摄像头和麦克风：getUserMedia ${reason.name} ${reason.message}`
              );
            } else if (
              [
                "AbortError",
                "NotAllowedError",
                "NotFoundError",
                "NotReadableError",
                "OverconstrainedError",
                "SecurityError",
                "TypeError",
              ].includes(reason.name)
            ) {
              alert(`getUserMedia ${reason.name} ${reason.message}`);
            }
          }
          publisher.close();
          // console.error(reason);
        });
    },
    Recorder(stream, that) {
      var sampleBits = 16; //输出采样数位 8, 16
      var sampleRate = 16000; //输出采样率
      var context = new AudioContext();
      var audioInput = context.createMediaStreamSource(stream);
      var recorder = context.createScriptProcessor(4096, 1, 1);
      var audioData = {
        size: 0, //录音文件长度
        buffer: [], //录音缓存
        inputSampleRate: 48000, //输入采样率
        inputSampleBits: 16, //输入采样数位 8, 16
        outputSampleRate: sampleRate, //输出采样数位
        oututSampleBits: sampleBits, //输出采样率
        clear: function () {
          this.buffer = [];
          this.size = 0;
        },
        input: function (data) {
          this.buffer.push(new Float32Array(data));
          this.size += data.length;
        },
        compress: function () {
          //合并压缩
          //合并
          var data = new Float32Array(this.size);
          var offset = 0;
          for (var i = 0; i < this.buffer.length; i++) {
            data.set(this.buffer[i], offset);
            offset += this.buffer[i].length;
          }
          //压缩
          var compression = parseInt(
            this.inputSampleRate / this.outputSampleRate
          );
          var length = data.length / compression;
          var result = new Float32Array(length);
          var index = 0,
            j = 0;
          while (index < length) {
            result[index] = data[j];
            j += compression;
            index++;
          }
          return result;
        },
        encodePCM: function () {
          //这里不对采集到的数据进行其他格式处理，如有需要均交给服务器端处理。
          var sampleRate = Math.min(
            this.inputSampleRate,
            this.outputSampleRate
          );
          var sampleBits = Math.min(this.inputSampleBits, this.oututSampleBits);
          var bytes = this.compress();
          var dataLength = bytes.length * (sampleBits / 8);
          var buffer = new ArrayBuffer(dataLength);
          var data = new DataView(buffer);
          var offset = 0;
          for (var i = 0; i < bytes.length; i++, offset += 2) {
            var s = Math.max(-1, Math.min(1, bytes[i]));
            data.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
          }
          return new Blob([data]);
        },
      };

      var sendData = function () {
        //对以获取的数据进行处理(分包)
        var reader = new FileReader();
        reader.onload = (e) => {
          var outbuffer = e.target.result;
          var arr = new Int8Array(outbuffer);
          if (arr.length > 0) {
            var tmparr = new Int8Array(1024);
            var j = 0;
            for (var i = 0; i < arr.byteLength; i++) {
              tmparr[j++] = arr[i];
              if ((i + 1) % 1024 == 0) {
                if (that.ws.readyState == 1) {
                  console.log(tmparr);
                  that.ws.send(tmparr);
                }
                if (arr.byteLength - i - 1 >= 1024) {
                  tmparr = new Int8Array(1024);
                } else {
                  tmparr = new Int8Array(arr.byteLength - i - 1);
                }
                j = 0;
              }
              if (i + 1 == arr.byteLength && (i + 1) % 1024 != 0) {
                if (that.ws.readyState == 1) {
                  that.ws.send(tmparr);
                }
              }
            }
          }
        };
        reader.readAsArrayBuffer(audioData.encodePCM());
        audioData.clear(); //每次发送完成则清理掉旧数据
      };

      this.start = function () {
        audioInput.connect(recorder);
        recorder.connect(context.destination);
      };

      this.stop = function () {
        recorder.disconnect();
      };

      this.getBlob = function () {
        return audioData.encodePCM();
      };

      this.clear = function () {
        audioData.clear();
      };

      recorder.onaudioprocess = function (e) {
        var inputBuffer = e.inputBuffer.getChannelData(0);
        audioData.input(inputBuffer);
        sendData();
      };
    },
    useWebSocket() {
      let that = this;
      this.ws = new WebSocket(
        // "wss://dsm.wisecommey.com:8443/ws/asset/" + that.video.push_stream_id
        "wss://dsm..ccom.com.cn:8443/ws/asset/" + that.video.push_stream_id
      );
      // this.ws = new WebSocket("wss://dsm.wisecommey.com:8443/ws/asset/123");
      this.ws.binaryType = "arraybuffer"; //传输的是 ArrayBuffer 类型的数据
      this.ws.onopen = function () {
        console.log("握手成功");
        console.log(that.ws);
        if (that.ws.readyState == 1) {
          //ws进入连接状态，则每隔500毫秒发送一包数据
          that.soundrecord.start();
        }
      };
      this.ws.onmessage = function (msg) {
        console.info(msg);
      };
      this.ws.onerror = function (err) {
        console.info(err);
      };
    },

    // SiteHistoryRecordBTN() {
    //   this.visibleHistory = true;
    //   this.recordwsid = this.ws_id;
    //   // console.log(recordwsid);
    //   // console.log(this.ws_id);
    // },
    // showModalHistory() {
    //   this.visibleHistory = true;
    // },
    // handleOkHistory(e) {
    //   this.ModalText = "The modal will be closed after two seconds";
    //   this.confirmLoadingHistory = true;

    //   this.visibleHistory = false;
    //   this.confirmLoadingHistory = false;
    // },
    // handleCancelHistory(e) {
    //   console.log("Clicked cancel button");
    //   this.visibleHistory = false;
    // },
  },
  beforeDestroy() {
    // window.location.reload();
    if (this.player) {
      this.player.close();
    }
    if (this.publisher) {
      this.publisher.close();
    }
    this.close();
    // this.talkEndTest();
    clearInterval(this.getvideoSignalTimer);
    this.getvideoSignalTimer = null;
  },
};
</script>

<style lang="less" scoped>
//所有控件
// video::-webkit-media-controls-enclosure {
//   display: block;
// }
// video::-webkit-media-controls-timeline {
//   display: block;
// }
/*controls进度条一直显示*/
// .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
//   opacity: 1;
// }
// .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
//   opacity: 1;
// }
// // 播放按钮
// video::-webkit-media-controls-play-button {
//   display: none !important;
// }
// 当前播放时间
// video::-webkit-media-controls-current-time-display {
//   display: none !important;
// }
// 剩余时间
// video::-webkit-media-controls-time-remaining-display {
//   display: none !important;
// }
// 音量按钮
// video::-webkit-media-controls-volume-control-container {
//   display: none !important;
// }
// 全屏
video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}
// 时间轴
video::-webkit-media-controls-timeline {
  display: none !important;
}
.myVideo_out {
  width: 100%;
  height: 200px;
  padding: 0;
  margin-bottom: 0;
}
</style>
