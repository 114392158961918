<template>
  <div class="NiceManager">
    <!-- 整洁度优项目经理 -->
    <div class="title">
      <div class="title_days">整洁度优项目经理</div>
    </div>
    <!--swiper-no-swiping 禁止拖动  -->
    <div
      class="swiper-container"
      :id="currentIndex"
      v-if="swiper_list.length > 0"
    >
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, i) in swiper_list"
          :key="i"
          :id="item.slider"
        >
          <div class="swiper-slide-img">
            <div v-if="item.w_photo == null" class="noPhoto">暂无图片</div>
            <div v-else class="img">
              <img :src="pathUrl + item.w_photo" alt="" />
            </div>
          </div>
          <div class="swiper-slide-name">
            <a-tooltip placement="bottomLeft">
              <template slot="title">
                <span> {{ item.w_name }}</span>
              </template>
              {{ item.w_name }}
            </a-tooltip>
          </div>
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <!-- <div class="swiper-pagination"></div> -->
      <!-- 如果需要导航按钮 -->
      <!-- <div class="swiper-button-prev"></div> -->
      <!-- <div class="swiper-button-next"></div> -->
    </div>
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
import { urlConfig } from "@/config/config";
import { GetBadsitejlworker, GetGoodsitejlworker } from "../../api/adminBoard";
export default {
  name: "NiceManager",
  props: {
    bc_id: {},
  },
  data() {
    return {
      currentIndex: "NiceManager",
      queryParam: { bc_id: "" },
      swiper_list: [
        // { w_photo: require("../data/datav.jpg"), w_name: "name" },
        // { w_photo: require("../data/inspection.png"), w_name: "name" },
        // { w_photo: require("../data/caseField.png"), w_name: "name" },
        // { w_photo: require("../data/datav.png"), w_name: "name" },
        // { slider: "5" },
        // { slider: "6" },
        // { slider: "7" },
        // { slider: "8" },
      ],
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
    };
  },
  created() {},
  mounted() {
    this.loadData();
  },
  watch: {
    bc_id(NValue, OValue) {
      this.queryParam.bc_id = NValue;
      this.loadData();
      // console.log(this.queryParam.bc_id);
    },
  },
  methods: {
    initSwiper() {
      let vm = this;
      var mySwiper = new Swiper("#" + vm.currentIndex, {
        loop: true, // 循环模式选项 当播放到最后一张图片后，点击按钮是否会继续轮播
        slidesPerView: 4, //一次显示4个
        // slidesPerView: 5, //一次显示10个
        slidesPerGroup: 1, //一次往前移一个
        spaceBetween: 5,
        // slidesPerColumn: 2, //多行 —— 一屏显示2行
        // loopedSlides: 1,
        // loopFillGroupWithBlank: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplay: true,
        autoplay: {
          //   delay: 1000 * 60, // 自动播放的间隔时间，单位ms，默认3000ms
          delay: 2000,
          disableOnInteraction: false, // 用户操作后是否停止自动播放，默认为true，即停止。改为false，表示不会停止自动播放
        },
        // 如果需要前进后退按钮
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        // 如果需要分页器 即轮播图下面的小圆点
        // pagination: {
        //   el: ".swiper-pagination",
        // },
        on: {
          // slideChangeTransitionEnd: function () {
          //   // vm.currentIndex;
          //   // console.log(vm.currentIndex);
          // },
          click: function (sw) {
            let target = sw.target;
            // console.log(target);
            vm.swiper_list.forEach((el) => {
              if (
                target.innerText.indexOf(el.w_name) != -1 ||
                target.src.indexOf(el.w_photo) != -1
              ) {
                // console.log(el.si_eventworker);
                // console.log(el);
                // 跳转到报警事件列表页面
                localStorage.setItem("path", "SiteNeat");
                vm.$router.push({
                  path: "/SiteNeat",
                  query: {
                    e_events: "1",
                    // w_name: el.w_name,
                    w_name_jl: el.w_name,
                    bc_id: vm.queryParam.bc_id,

                    e_time_start: Math.round(
                      (new Date().getTime() - 24 * 60 * 60 * 1000 * 7) / 1000
                    ),
                    e_time_end: Math.round(new Date().getTime() / 1000),
                  },
                });
              }
            });
          },
        },
      });
    },
    async loadData() {
      this.swiper_list = [];
      const orderParam = ["bc_id"];
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      await GetGoodsitejlworker(params)
        .then((res) => {
          // console.log("整洁度差项目经理", res.data);
          this.swiper_list = res.data;
        })
        .then(() => {
          this.initSwiper(); //不能放在created里
        });
    },
  },
};
</script>

<style lang="less" scoped>
.NiceManager {
  width: 100%;
  height: 100%;
  .title {
    width: 100%;
    height: 15%;
    color: #66ffff;
    text-align: left;
    padding-left: 10px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    .title_days {
      width: 50%;
      img {
        width: 8%;
        height: 40%;
      }
    }
  }
  .swiper-container {
    width: 100%;
    height: 85%;
    .swiper-slide {
      // background-color: pink;
      cursor: pointer;
      .swiper-slide-img {
        width: 100%;
        height: 80%;
        // background-color: #fff;
        .noPhoto {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .img {
          height: 100%;
          width: 100%;
        }
        img {
          height: 100%;
          width: 100%;
        }
      }
      .swiper-slide-name {
        width: 100%;
        height: 20%;
        // background-color: #fff;
        color: #d5f3f3;
        // display: flex;
        justify-content: center;
        align-items: center;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
</style>
