import request from "@/utils/request";
// 管理端看板接口
const api = {
  getworkerattendance: "/dsmcore/api/newscreen/workerattendance", // 管理端看板员工出勤
  onworksite: "/dsmcore/api/newscreen/onworksite", // 管理端看板在建工地
  newonworksite: "/dsmcore/api/newscreen/newonworksite", // 管理端看板新开工工地
  linknoworksite: "/dsmcore/api/newscreen/linknoworksite", // 管理端看板连续无人施工天数
  sumnoworksite: "/dsmcore/api/newscreen/sumnoworksite", //管理端看板累计无人施工天数
  badsitejlworker: "/dsmcore/api/newscreen/badsitejlworker", //管理端看板整洁度差项目经理
  bindworksite: "/dsmcore/api/newscreen/bindworksite", //管理端看板已绑定工地数量
  newbindworksite: "/dsmcore/api/newscreen/newbindworksite", //管理端看板新绑定工地数量
  wscamerabadpic: "/dsmcore/api/newscreen/wscamerabadpic", //管理端看板摄像头歪曲
  wscleanbadpic: "/dsmcore/api/newscreen/wscleanbadpic", //管理端看板整洁度差工地快照
  wsmokeevent: "/dsmcore/api/newscreen/wsmokeevent", //管理端看板吸烟报警
  wclothesevent: "/dsmcore/api/newscreen/wclothesevent", //管理端看板工服报警
  lowonlinejl: "/dsmcore/api/newscreen/lowonlinejl", //管理端看板在线率低项目经理
  // newbindworksite: "/dsmcore/api/newscreen/newbindworksite", //新绑定工地数量
  syncsmoke: "/dsmcore/api/newscreen/syncsmoke", //抽烟实时告警
  syncclothes: "/dsmcore/api/newscreen/syncclothes", //未穿工服实时告警
  goodsitejlworker: "/dsmcore/api/newscreen/goodsitejlworker", //整洁度优项目经理
  syncattendance: "/dsmcore/api/newscreen/syncattendance", //考勤实时告警
  wscleangoodpic: "/dsmcore/api/newscreen/wscleangoodpic", //整洁度优工地照片
  goodsitegjworker: "/dsmcore/api/newscreen/goodsitegjworker", //整洁度优工程管家
};
export function GetWorkerattendance(parameter) {
  return request({
    url: api.getworkerattendance,
    method: "post",
    params: parameter,
  });
}
export function GetOnworksite(parameter) {
  return request({
    url: api.onworksite,
    method: "post",
    params: parameter,
  });
}
export function GetNewonworksite(parameter) {
  return request({
    url: api.newonworksite,
    method: "post",
    params: parameter,
  });
}
export function GetLinknoworksite(parameter) {
  return request({
    url: api.linknoworksite,
    method: "post",
    params: parameter,
  });
}
export function GetSumnoworksite(parameter) {
  return request({
    url: api.sumnoworksite,
    method: "post",
    params: parameter,
  });
}
export function GetBadsitejlworker(parameter) {
  return request({
    url: api.badsitejlworker,
    method: "post",
    params: parameter,
  });
}
export function GetBindworksite(parameter) {
  return request({
    url: api.bindworksite,
    method: "post",
    params: parameter,
  });
}

export function GetNewbindworksite(parameter) {
  return request({
    url: api.newbindworksite,
    method: "post",
    params: parameter,
  });
}
export function GetWscamerabadpic(parameter) {
  return request({
    url: api.wscamerabadpic,
    method: "post",
    params: parameter,
  });
}
export function GetWscleanbadpic(parameter) {
  return request({
    url: api.wscleanbadpic,
    method: "post",
    params: parameter,
  });
}

export function GetWsmokeevent(parameter) {
  return request({
    url: api.wsmokeevent,
    method: "post",
    params: parameter,
  });
}
export function GetWclothesevent(parameter) {
  return request({
    url: api.wclothesevent,
    method: "post",
    params: parameter,
  });
}
export function GetLowonlinejl(parameter) {
  return request({
    url: api.lowonlinejl,
    method: "post",
    params: parameter,
  });
}

export function GetSyncsmoke(parameter) {
  return request({
    url: api.syncsmoke,
    method: "post",
    params: parameter,
  });
}

export function GetSyncclothes(parameter) {
  return request({
    url: api.syncclothes,
    method: "post",
    params: parameter,
  });
}
export function GetGoodsitejlworker(parameter) {
  return request({
    url: api.goodsitejlworker,
    method: "post",
    params: parameter,
  });
}
export function GetSyncattendance(parameter) {
  return request({
    url: api.syncattendance,
    method: "post",
    params: parameter,
  });
}
export function GetWscleangoodpic(parameter) {
  return request({
    url: api.wscleangoodpic,
    method: "post",
    params: parameter,
  });
}
export function GetGoodsitegjworker(parameter) {
  return request({
    url: api.goodsitegjworker,
    method: "post",
    params: parameter,
  });
}
