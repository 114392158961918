<template>
  <div class="SmokingAlarm">
    <!-- 抽烟报警-->
    <div class="title">
      <div class="title_days">
        抽烟报警
        <a-tooltip
          placement="right"
          :get-popup-container="getPopupContainer"
          overlayClassName="popover"
        >
          <template slot="title">今日所有人员抽烟报警</template>
          <img
            src="../../assets/images/visualize/title_icon.png"
            style="padding: 0"
          />
        </a-tooltip>
      </div>
    </div>
    <!--swiper-no-swiping 禁止拖动  -->
    <div
      class="swiper-container"
      id="swiper-container-SmokingAlarm"
      v-if="swiper_list.length > 0"
    >
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, i) in swiper_list"
          :key="i"
          :data-href="sliteItem(item)"
          @click="handleClickSlide(item)"
        >
          <div class="img_info" :id="item.e_img" @click="swiperClick(item)">
            <!-- <div class="img"><img :src="item.e_img" alt="" /></div> -->
            <div class="img"><img :src="pathUrl + item.e_img" alt="" /></div>
            <div class="info">
              <div class="name">
                姓名：{{ item.si_eventworker
                }}<span class="w_post_JL" v-if="item.w_post == '项目经理'">{{
                  item.w_post
                }}</span>
                <span class="w_post_GJ" v-else-if="item.w_post == '工程管家'">{{
                  item.w_post
                }}</span>
                <span class="w_post" v-else-if="item.w_post == ''">访客</span>
                <span class="w_post" v-else>{{ item.w_post }}</span>
              </div>
              <div class="ws_name">
                工地：
                {{ item.ws_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="empty">暂无数据</div>
  </div>
</template>

<script>
import Swiper from "swiper"; // 注意引入的是Swiper
import "swiper/css/swiper.min.css"; // 注意这里的引入
import { urlConfig } from "@/config/config";
import {
  GetWsmokeevent,
  GetWclothesevent,
  GetWscleanbadpic,
} from "../../api/adminBoard";
export default {
  name: "SmokingAlarm",
  props: {
    bc_id: {},
    showDrawer: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      mySwiper: "",
      swiper_list: [],
      pathUrl:
        urlConfig.baseUrl + "/files/picture/snapshot/snapshotpicture?filePath=",
      queryParam: { bc_id: "" },
      record: {
        ws_id: "",
        ws_name: "",
      },
    };
  },
  created() {},
  mounted() {
    this.loadData();
  },
  updated() {
    // if (this.mySwiper) {
    //   this.$nextTick(() => {
    //     this.mySwiper.loopDestroy();
    //     this.mySwiper.loopCreate();
    //   });
    // }
  },
  watch: {
    bc_id(NValue, OValue) {
      this.queryParam.bc_id = NValue;
      this.loadData();
      console.log(this.queryParam.bc_id);
    },
  },
  methods: {
    getPopupContainer(trigger) {
      return trigger.parentElement;
    },
    initSwiper() {
      let vm = this;
      //   this.mySwiper = new Swiper("#swiper-container", {
      var mySwiper = new Swiper("#swiper-container-SmokingAlarm", {
        // effect: "coverflow",

        centeredSlides: true,
        loop: true, // 循环模式选项 当播放到最后一张图片后，点击按钮是否会继续轮播
        initialSlide: 1,
        direction: "vertical", // 垂直切换
        slidesPerView: 3, //一次显示3个
        // slidesPerGroup: 1, //一次往前移一个
        spaceBetween: 5,
        // slidesPerColumn: 2, //多行 —— 一屏显示2行
        // loopedSlides: 1,
        // loopFillGroupWithBlank: true,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: false, //修改swiper的父元素时，自动初始化swiper
        autoplay: true,
        autoplay: {
          //   delay: 1000 * 60, // 自动播放的间隔时间，单位ms，默认3000ms
          delay: 2000,
          disableOnInteraction: false, // 用户操作后是否停止自动播放，默认为true，即停止。改为false，表示不会停止自动播放
        },

        on: {
          onSlideChangeEnd: function (swiper) {
            swiper.update();
            mySwiper.startAutoplay();
            mySwiper.reLoop();
          },
          //   slideChangeTransitionEnd: function () {},
          // click: function (swiper, event) {
          //   console.log(this.realIndex);
          // },
          click: function (swiper) {
            // console.log("抽烟报警swiper", swiper.target);
            let target = swiper.target;
            // console.log(swiper);
            // 循环判断返回的数据里是否包含swiper.target
            // 获取dom元素的内容  src里的innerText为空
            vm.swiper_list.forEach((el, index) => {
              // console.log(target.innerText);
              // console.log(target);

              // if (this.realIndex == index && !target.src) {
              //   // console.log(target);
              //   // 跳转到报警事件列表页面
              //   localStorage.setItem("path", "AlarmEvent");
              //   vm.$router.push({
              //     path: "/AlarmEvent",
              //     query: {
              //       e_events: "smoking",
              //       si_eventworker: el.si_eventworker == "" ? "2" : "1",
              //       bc_id: vm.queryParam.bc_id,
              //     },
              //   });
              // }
              if (target.innerText.indexOf(el.ws_name) != -1) {
                console.log(el.ws_name);
                localStorage.setItem("path", "AlarmEvent");
                vm.$router.push({
                  path: "/AlarmEvent",
                  query: {
                    e_events: "smoking",
                    si_eventworker: el.si_eventworker == "" ? "2" : "1",
                    bc_id: vm.queryParam.bc_id,
                  },
                });
              } else if (this.realIndex == index && !target.src) {
                // console.log(target);
                // 跳转到报警事件列表页面
                localStorage.setItem("path", "AlarmEvent");
                vm.$router.push({
                  path: "/AlarmEvent",
                  query: {
                    e_events: "smoking",
                    si_eventworker: target.innerText != "访客" ? "1" : "2",
                    bc_id: vm.queryParam.bc_id,
                  },
                });
              }
              // 点击图片显示直播   改为跳转
              if (target.src && target.src.indexOf(el.e_img) != -1) {
                // console.log(el.ws_id);
                // vm.record = el;
                // // console.log(vm.record);
                // vm.$emit("record", vm.record);
                // vm.showDrawer();
                localStorage.setItem("path", "AlarmEvent");
                vm.$router.push({
                  path: "/AlarmEvent",
                  query: {
                    e_events: "smoking",
                    si_eventworker: el.si_eventworker == "" ? "2" : "1",
                    bc_id: vm.queryParam.bc_id,
                  },
                });
              }
            });
          },
        },
      });
    },
    // 开启loop模式，拿不到第二轮首页数据
    handleClickSlide(index) {
      // console.log(index);
    },
    sliteItem(item) {
      // console.log(item); //可以获取所有的数据
    },
    // 开启loop模式，拿不到第二轮首页数据
    swiperClick(item) {
      // console.log(item.si_eventworker);
    },
    async loadData() {
      this.swiper_list = [];
      const orderParam = ["bc_id"];
      const param = Object.assign({}, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      await GetWsmokeevent(params)
        // await GetWclothesevent(params)
        .then((res) => {
          // console.log("抽烟报警", res);

          this.swiper_list = res.data;
        })
        .then(() => {
          this.initSwiper(); //不能放在created里
        });
    },
  },
};
</script>

<style lang="less" scoped>
.SmokingAlarm {
  width: 100%;
  height: 100%;
  .title {
    width: 100%;
    height: 15%;
    color: #66ffff;
    text-align: left;
    padding-left: 10px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    .title_days {
      width: 50%;
      display: flex;
      align-items: center;
      img {
        margin-left: 5px;
        width: 12px;
        height: 12px;
      }
    }
  }
  .swiper-container {
    width: 100%;
    height: 85%;
    .swiper-slide {
      // background-color: pink;
      cursor: pointer;
      .img_info {
        display: flex;
        width: 100%;
        height: 100%;
      }
      .img {
        width: 30%;
        height: 100%;
        display: flex;
        // justify-content: center;
        align-items: center;
        // background-color: #fff;
        img {
          width: 100%;
          height: 90%;
          // width: auto;
          // height: auto;
          // max-width: 100%;
          // max-height: 100%;
          // width: 90px;
          // height: 100px;
        }
      }
      .info {
        height: 100%;
        width: 80%;
        text-align: left;
        color: #ffff;
        .name {
          padding: 10px;
          padding-bottom: 0;
          .w_post_JL,
          .w_post_GJ,
          .w_post {
            border-radius: 10px;
            padding: 2px 10px;
          }
          .w_post_JL {
            background-color: #169bd5;
          }
          .w_post_GJ {
            background-color: #7b4d12;
          }
          .w_post {
            background-color: #008080;
          }
        }
        .ws_name {
          padding: 10px;
          //  width: 200px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85%;
  }
}
</style>
