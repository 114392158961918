<template>
  <div id="china_map">
    <div class="control_left">
      <div class="status-con">
        <a-tooltip placement="right">
          <template slot="title">
            <span>施工中-在建工地中绑定设备且设备在线的工地</span>
          </template>
          <div class="status-item" :class="{ status1: device_status === '1' }">
            <img src="../../assets/images/map_success.png" alt="" />
            <span>绑定在线</span>
          </div>
        </a-tooltip>
        <a-tooltip placement="right">
          <template slot="title">
            <span>施工中-在建工地中绑定设备且设备离线的工地</span>
          </template>
          <div class="status-item status3">
            <img src="../../assets/images/map_error.png" alt="" />
            <span>绑定离线</span>
          </div>
        </a-tooltip>

        <a-tooltip placement="right">
          <template slot="title">
            <span>工序为“工程竣工、整体交付”状态的是竣工工地</span>
          </template>
          <div class="status-item">
            <img src="../../assets/images/map_warn.png" alt="" />
            <span class="colorBlue">竣工工地</span>
          </div>
        </a-tooltip>
      </div>
    </div>
    <div id="container" style="width: 100%; height: 100%"></div>
    <canvas id="canvas"></canvas>
  </div>
</template>

<script>
import { SSearch } from "@/components";
import { GetSiteMap, Getmapsitedetail } from "@/api/site";
import Detail from "../inspection/detail.vue";
import AdminePreview from "../adminBoard/AdminePreview.vue";

import SitePreview from "../site/SitePreview.vue";
import Vue from "vue";
const centerList = {
  "": "北京市",
  0: "北京市",
  5: "天津市",
  8: "天津市",
  20: "天津市",
  3: "北京市",
  4: "北京市",
  6: "北京市",
  23: "北京市",
  24: "北京市",
  25: "北京市",
  9: "成都市",
  17: "大连市",
  15: "哈尔滨市",
  14: "济南市",
  13: "南京市",
  22: "青岛市",
  10: "青岛市",
  7: "石家庄市",
  12: "武汉市",
  16: "西安市",
  19: "西安市",
  21: "西安市",
  18: "郑州市",
  11: "重庆市",
  26: "北京市",
};
export default {
  name: "SiteMap",
  components: {
    SSearch,
    GetSiteMap,
    Detail,
    AdminePreview,
    SitePreview,
  },
  props: {
    bc_id: {
      // type: String,
      // default: "",
    },
    showDrawer: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      ws_id: "",
      mapHeight: "100%",
      queryParam: {},
      queryParam1: {
        camera: "",
      },
      bcName: "",
      points1: [],
      show: false,
      zai_li_xian: "",
      device_status: "",
      Camera: "1",
      // 弹出框
      title: "",
      visible: false,
      record: {
        ws_id: "",
        ws_name: "",
      },
      // center: "北京市",
      center: "",
      text: `A dog is a type of domesticated animal.`,
      customStyle: "border-radius: 4px;border: 0;overflow: hidden",
      bind_onLine: "", // 绑定在线率
      isPreviewShow: false,
      OnLineLength: "",
      OutLineLength: "",
      content: "",
      oldRecord: {},
    };
  },
  created() {
    this.ws_id = this.$route.query.ws_id;
    window.JumpSubpage = this.JumpSubpage;
    window.jumpToSiteList = this.jumpToSiteList;
  },
  watch: {
    // 监听分公司查询条件的变化，改变地图中心点的位置
    bc_id(newValue, old) {
      this.fetchMap();
      // console.log(newValue, old);
      // console.log(newValue);
      // 根据分公司id设置中心点
      function formatData(a) {
        return centerList[a];
      }
      this.center = formatData(newValue);
      // console.log(this.center);
    },
    record(newVal, oldVal) {
      this.oldRecord = oldVal;
      // console.log(this.oldRecord.ws_id);
    },
  },
  mounted() {
    this.fetchData();
    var myCity = new BMapGL.LocalCity();
    myCity.get(this.cityResult);
    // this.initMap();
    // this.content = this.$refs["ShowPreviewId"].$el.innerHTML;
  },
  methods: {
    cityResult(result) {
      var cityName = result.name;
      this.center = cityName;
      console.log("mounted_cityResult", cityName, this.cityCenter);
    },
    render(h) {
      return h(AdminePreview, {
        props: {
          record: this.record,
        },
      });
    },
    fetchData(data) {
      this.queryParam = data
        ? data
        : {
            // ws_area: "",
            bc_name: this.bc_id != "0" ? this.bc_id : "",
            // w_name: "",
            ws_name: "",
            w_name_gj: "",
            camera: "1", //有无摄像头
            // d_status: "",
            no_work: "",
            device_status: "",
            ws_bind_status: "",
            w_name_jl: "",
            ws_id: "",
          };
      this.fetchMap();
    },

    async fetchMap() {
      // this.center = this.queryParam.ws_area; // 设置当前地图区域
      let order = [
        "bc_name",
        "ws_name",
        "w_name_jl",
        "w_name_gj",
        "camera",
        "device_status",
        "no_work",
      ];
      let params = {
        ...this.queryParam,
        ws_id: this.queryParam.ws_id,
        // bc_name: this.queryParam.bc_name,
        bc_name: this.bc_id == 0 ? "" : this.bc_id,
        ws_name: this.queryParam.ws_name,
        w_name_jl: this.queryParam.w_name_jl, //工地项目经理
        w_name_gj: this.queryParam.w_name_gj, //工地管家
        camera: this.queryParam.camera, //有无摄像头
        device_status: this.queryParam.device_status, //摄像头状态
        no_work: this.queryParam.no_work,
        gj_phone: this.queryParam.gj_phone,
        jl_phone: this.queryParam.jl_phone,
        verify: this.$verify(
          {
            ...this.queryParam,
            ws_id: this.queryParam.ws_id,
            bc_name: this.bc_id == 0 ? "" : this.bc_id,
            ws_name: this.queryParam.ws_name,
            w_name_jl: this.queryParam.w_name_jl, //工地项目经理
            w_name_gj: this.queryParam.w_name_gj, //工地管家
            camera: this.queryParam.camera, //有无摄像头
            device_status: this.queryParam.device_status, //摄像头状态
            no_work: this.queryParam.no_work,
            gj_phone: this.queryParam.gj_phone,
            jl_phone: this.queryParam.jl_phone,
          },
          order
        ),
      };

      // this.bc_id = params["bc_name"] ? params["bc_name"] : "";
      // const points = [];
      const points1 = [];
      const points2 = [];
      let device_statusLength = []; //在线状态
      let ws_statusLength = []; //绑定状态

      await GetSiteMap(params)
        .then((res) => {
          // console.log(res.data[0].ws_name);
          res.data.forEach((element) => {
            if (element.device_status == 1) {
              device_statusLength.push(element);
            }
          });
          // console.log("绑定状态", ws_statusLength);
          // console.log("在线状态", device_statusLength.length);
          this.bind_onLine =
            (device_statusLength.length / ws_statusLength) * 100;
          this.OnLineLength = device_statusLength.length; //在线数量

          this.bind_onLine = this.bind_onLine.toFixed(2);
          // bind_onLine;
          // console.log(this.bind_onLine);
          if (res && res.data) {
            res.data.forEach((el) => {
              if (
                el.ws_long &&
                el.ws_lat &&
                el.device_status == "2" &&
                el.camera != "2"
              ) {
                points2.push({ lng: el.ws_long, ws_id: el.ws_id });
              }
              this.OutLineLength = points2.length;
              points1.push({
                lng: el.ws_long,
                ws_id: el.ws_id,
                lat: el.ws_lat,
                bc_name: el.bc_name, //分公司id
                camera: el.camera, //摄像头
                device_status: el.device_status, //摄像头状态
                no_work: el.no_work, //无人施工
                ws_status: el.ws_status, //工地状态
                show: false,
              });
            });
            this.points1 = points1;
          } else {
            this.$message.error("地图返回数据不正确");
          }
        })
        .then(() => {
          this.initMap();
        });
    },
    initMap() {
      // console.log(this.$refs.ShowPreviewId);

      let that = this;
      let map = null;
      function baiduMapInit() {
        map = new BMapGL.Map("container"); // 创建地图实例
        // map.centerAndZoom(that.center, 10); // 初始化地图，设置中心点坐标和地图级别
        // map.setMapType(BMAP_EARTH_MAP);

        map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放

        function cityResult(result) {
          var cityName = result.name;
          //核心类Map的方法：setCenter(center:Ponit|String)
          //设置地图中心点。center除了可以为坐标点以外，还支持城市名
          // map.setCenter(cityName);
          // alert("当前定位城市:" + cityName);
          that.cityCenter = cityName;
          console.log(cityName, that.cityCenter);
        }
        var myCity = new BMapGL.LocalCity();
        myCity.get(cityResult);
        setTimeout(() => {
          // 初始化地图，设置中心点坐标和地图级别
          map.centerAndZoom(
            that.center != "" ? that.center : that.cityCenter,
            10
          );
        }, 500);

        function makeShowInfoString(objUserInfo) {
          var html = [];
          if (objUserInfo.device_status == "1") {
            that.record = objUserInfo;
            console.log(that.record.ws_id);
            that.isPreviewShow = true;
            // 获取组件的html
            var vm = new Vue({
              render: (h) =>
                h(AdminePreview, {
                  props: {
                    record: objUserInfo,
                    isPreviewShow: that.isPreviewShow,
                  },
                }),
            });
            vm.$mount();

            // if (that.record.ws_id == that.oldRecord.ws_id) {
            // } else {
            //   vm.$mount();
            //   console.log(that.record.ws_id);
            //   console.log(that.oldRecord.ws_id);
            //   console.log("2222");
            // }
            // console.log(vm);

            var componentHtml = vm.$el.outerHTML;
            // console.log(componentHtml);
            html[0] = componentHtml;
          }
          console.log(html);
          html.push(` <div style:"width:300px">

                        <span>${
                          objUserInfo.ws_status == "1" ? "施工中" : "施工结束"
                        }</span>
                       <span style="color: green"   >  ${
                         objUserInfo.device_status == "1" ? "在线" : ""
                       } </span>
                       <span style="color: red" >
                      ${
                        objUserInfo.device_status == "2"
                          ? objUserInfo.camera == "1"
                            ? "离线"
                            : ""
                          : ""
                      }
                       </span>
                  
                   </div>
                 <a  onclick="jumpToSiteList('SiteParticulars')" title="点击查看工地信息" > ${
                   objUserInfo.ws_name
                     ? objUserInfo.ws_name.replace(/直辖市|-北京|-天津/g, "")
                     : ""
                 } </a>
            <div>
              <span>工程管家：</span><span>${
                objUserInfo.w_name_gj ? objUserInfo.w_name_gj : ""
              }  </span>
              <span>${objUserInfo.gj_phone ? objUserInfo.gj_phone : ""}</span>
            </div>
            <div>
              <span>项目经理：</span>${
                objUserInfo.w_name_jl ? objUserInfo.w_name_jl : ""
              }<span>${objUserInfo.jl_phone ? objUserInfo.jl_phone : ""}</span>
            </div>

           `);

          return html.join("");
        }

        var data = [];
        that.points1.forEach((el) => {
          if (el.lng != null && el.lng.length > 8) {
            // console.log(el.lng, el.lat);
            if (el.device_status == "1") {
              //在线
              data.push({
                geometry: {
                  type: "Point",
                  coordinates: [el.lng, el.lat],
                },
                ws_id: el.ws_id,
                camera: el.camera, //摄像头
                device_status: el.device_status, //摄像头状态
                ws_status: el.ws_status, //工地状态
                properties: {
                  width: 30,
                  height: 35,
                  icon: require("../../assets/images/green.png"), //有效icon
                },
              });
            } else if (el.camera == "2" && el.device_status == "2") {
              data.push({
                geometry: {
                  type: "Point",
                  coordinates: [el.lng, el.lat],
                },
                ws_id: el.ws_id,
                camera: el.camera, //摄像头
                device_status: el.device_status, //摄像头状态
                ws_status: el.ws_status, //工地状态
                properties: {
                  width: 30,
                  height: 35,
                  icon: require("../../assets/images/blue.png"),
                },
              });
            } else if (el.device_status == "2" && el.camera != "2") {
              //离线
              data.push({
                geometry: {
                  type: "Point",
                  coordinates: [el.lng, el.lat],
                },
                ws_id: el.ws_id,
                camera: el.camera, //摄像头
                device_status: el.device_status, //摄像头状态
                ws_status: el.ws_status, //工地状态
                properties: {
                  width: 30,
                  height: 35,
                  icon: require("../../assets/images/red.png"), //有效icon
                },
              });
            }
          }
        });

        var view = new mapvgl.View({
          map: map,
        });
        let myIcon = require("../../assets/images/map_success.png");
        // var marker = new BMapGL.Marker(data, { icon: myIcon });
        // map.addOverlay(marker);
        var infoWindow = new BMapGL.InfoWindow(""); // 创建信息窗口对象
        // infoWindow.width = "350px";
        var layer = new mapvgl.IconLayer({
          icon: require("../../assets/images/map_success.png"),
          enablePicked: true, // 是否可以拾取
          selectedIndex: -1, // 选中项
          // selectedColor: "rgba(255, 255, 255, 0.5)", // 选中项颜色
          // title: <AdminePreview />,
          // title:``,
          selectedColor: "red",
          // content: that.$refs["ShowPreviewId"].$el,
          autoSelect: true, // 根据鼠标位置来自动设置选中项
          onClick: (el) => {
            // if (el.dataItem.device_status == "1") {
            //   that.isPreviewShow = true;
            // }
            // 点击事件
            // console.log(el.dataItem.ws_id);
            if (el) {
              // 点击时，根据接口，获取信息
              let params = {
                ws_id: el.dataItem.ws_id,
              };
              let mapsitedetailData = {};
              Getmapsitedetail(params)
                .then((res) => {
                  // console.log(res.data);
                  mapsitedetailData = res.data;
                })
                .then(() => {
                  const objUserInfo = {
                    // name: mapsitedetailData.ws_status,
                    // number: "131424535464",
                    // sipState: "在线",
                    longitude: el.dataItem.geometry.coordinates[0],
                    latitude: el.dataItem.geometry.coordinates[1],
                    ws_id: el.dataItem.ws_id,
                    ws_name: mapsitedetailData.ws_name.replace(
                      /直辖市|-北京|-天津/g,
                      ""
                    ), //工地名称
                    // ws_name: el.dataItem.ws_name, //工地名称
                    w_name_gj: mapsitedetailData.gj_name, //工地管家
                    w_name_jl: mapsitedetailData.jl_name, //工地项目经理
                    bc_name: el.dataItem.bc_name, //分公司id
                    camera: el.dataItem.camera, //摄像头
                    device_status: el.dataItem.device_status, //摄像头状态
                    no_work: mapsitedetailData.no_work, //无人施工
                    ws_status: el.dataItem.ws_status, //工地状态
                    gj_phone: mapsitedetailData.gj_phone,
                    jl_phone: mapsitedetailData.jl_phone,
                    ws_status: mapsitedetailData.ws_status, //施工状态
                  };
                  const html = makeShowInfoString(objUserInfo);
                  // infoWindow.setTitle("");
                  // objUserInfo.name + "(" + objUserInfo.sipState + ")"
                  // html.appendChild(<AdminePreview />);
                  // console.log(html);

                  infoWindow.setContent(html);
                  infoWindow.redraw();
                  infoWindow.disableCloseOnClick();
                  var pt = new BMapGL.Point(
                    el.dataItem.geometry.coordinates[0],
                    el.dataItem.geometry.coordinates[1]
                  );
                  map.openInfoWindow(infoWindow, pt);
                });
            } else {
              return;
            }
          },
          onDblClick: (e) => {
            console.log("double click", e);
          },
          onRightClick: (e) => {
            console.log("right click", e);
          },
        });
        infoWindow.addEventListener("close", () => {
          that.isPreviewShow = false;
          // console.log("close");
        });
        // infoWindow._config.title = <AdminePreview />;

        view.addLayer(layer);
        // console.log(layer);
        // console.log(infoWindow);

        layer.setData(data);
        map.setDefaultCursor("default");
      }
      baiduMapInit();
    },
    jumpToSiteList(ref) {
      this.$emit("record", this.record);
      this.showDrawer(ref);
    },
  },
};
</script>

<style lang="less" scoped>
#ShowPreviewId {
  display: none;
  width: 0;
  height: 0;
}
#china_map {
  width: 100%;
  height: 100%;
}
.BMap_mask {
  height: 100%;
}
.explain_map {
  height: 25px;
  line-height: 15px;
  text-align: left;
  p {
    margin: 0;
    padding: 0;
  }
}
icon {
  width: 30px;
}
.info {
  width: 320px;
  // width: 100%;
  // height: 100%;
  // background-color: pink;
  .previewInfo {
    // width: 300px;
    width: 100%;
    // height: 170px;
    height: 195px;
    margin-top: 15px;
    // height: 60%;
    // background-color: pink;
    overflow: hidden;
  }
}
.bm-view {
  width: 100%;
}
.status-con {
  width: 170px;
  background: rgba(255, 255, 255, 0.5);
  border: rgba(0, 0, 0, 0.342) solid 1px;
  // border-radius: 16px;
  // margin: 20px ;
  // display: flex;
  img {
    width: 25px;
    height: 30px;
    margin-right: 5px;
  }
}
.status-item {
  margin: 10px 0;
  // padding: 0 15px;
  color: rgb(2, 102, 2);
  font-weight: 800;
  .colorGray {
    color: rgb(68, 68, 68);
  }
  .colorBlue {
    color: blue;
  }
}
.status-item + .status-item {
  border-left: 1px solid #ccc;
}
// .status-item:hover {
//   //   cursor: pointer;
//   //   color: #1890ff;
// }
.status1 {
  color: #52c41a;
}
.status2 {
  // color: #faad14;
  color: #397ef9;
}
.status3 {
  color: #c6415b;
}
.status-icon {
  display: block;
  padding-bottom: 5px;
  font-size: 18px;
}

.TClist {
  height: 40px;
  line-height: 40px;
  span {
    background-color: black;
    color: #fff;
    margin-right: 2px;
    padding: 9px;
    a {
      color: white;
    }
  }
  .zhibo {
    background: url(../../assets/images/site_img/zhibo.png);
  }
  .huifang {
    background: url(../../assets/images/site_img/huifang.png);
  }
  .kaoqin {
    background: url(../../assets/images/site_img/kaoqin.png);
  }
  .kuaizhao {
    background: url(../../assets/images/site_img/kuaizhao.png);
  }
}
.bm-marker-icon /deep/ .BMap_Marker {
  background-size: 100%;
}
/deep/ .BMap_cpyCtrl,
/deep/ .anchorBL {
  display: none;
}
.bm_control {
  // position: static !important;
  // position: relative;
  // margin-top: 60%;
  // margin-bottom: 0;
  // bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
}
/deep/ .ant-collapse-borderless,
.ant-collapse {
  background-color: transparent !important;
}
.bind_onLine {
  // font-weight: 800;

  margin: 10px;
  margin-left: 10px;
  // border: 1px solid #0c4170;
  // background: linear-gradient(
  //   to right,
  //   rgba(255, 255, 255, 0.8) 0%,
  //   rgba(255, 255, 255, 0.1) 50%,
  //   rgba(255, 255, 255, 0.8) 100%
  // );
  // background: radial-gradient(
  //   rgba(255, 255, 255, 0.8),
  //   rgba(255, 255, 255, 0.8),
  //   rgba(255, 255, 255, 0.5),
  //   rgba(255, 255, 255, 0.5)
  // );
  font-size: 14px;
  // color: white;
  .value {
    font-weight: 800;
    color: rgb(3, 70, 3);
    // color: #0c4170;
    font-size: 20px;
  }
}
.control_left {
  position: absolute;
  // bottom: 15px;
  bottom: 1%;
  z-index: 999;
}
/deep/.Marker_offline {
  z-index: 4000 !important;
}
/deep/ .Marker_noCamera {
  z-index: 3000 !important;
}
/deep/ .Marker_online {
  z-index: 5000 !important;
}
#canvas {
  width: 0%;
  height: 0%;
}
#container {
  width: 100%;
  height: 100%;
}
/deep/.movePreview {
  cursor: pointer;
}
/deep/.BMap_bubble_content {
  width: 350px !important;
}
/deep/.BMap_bubble_center {
  width: 350px !important;
}
/deep/.BMap_bubble_top {
  width: 350px !important;
}
/deep/.BMap_bubble_pop {
  width: 380px !important;
}
</style>
